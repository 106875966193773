/* ===================================
	05. Custom
====================================== */
section {padding: 130px 0; overflow: hidden;}
section.big-section {padding:160px 0;}
section.extra-big-section {padding:200px 0;}
section.half-section {padding:80px 0;}
label {margin-bottom: 5px; font-weight: 700;}
.overlap-section {margin-top:-14%; position: relative}
.d-inherit {display: inherit !important;}

/* input */
.input-border-bottom {background: transparent; border: 0; border-bottom: 1px solid $border-color; padding: 10px 0; margin-bottom: 30px; font-size: 14px; border-radius: 0;}
.input-border-bottom:focus{background: transparent; border: 0; border-bottom: 1px solid $primary;}
.input-border-bottom::-webkit-input-placeholder {font-weight: 300; font-size: 14px;}
.input-border-bottom::-moz-placeholder {font-weight: 300; font-size: 14px;} 
.input-border-bottom:-ms-input-placeholder {font-weight: 300; font-size: 14px;}

.extra-big-input, .extra-big-textarea, .extra-big-select select {padding: 18px 25px; font-size: 14px; line-height: 24px; height: 62px;}
.big-input, .big-textarea, .big-select select {padding: 18px 25px; font-size: 14px; border-radius: 0;}

.medium-input, .medium-textarea, .medium-select select {padding: 12px 20px; font-size: 14px; line-height: normal; border-radius: 0;}
.small-input, .small-textarea {padding: 12px 15px; font-size: 11px; line-height: normal}
.small-select select {padding: 15px 15px; line-height: normal}
.medium-input-light, .medium-textarea-light, .medium-select-light select {padding: 12px 25px; font-size: 12px; line-height: normal}

.extra-small-input, .extra-small-textarea, .extra-small-select select {padding: 9px 17px; font-size: 12px; line-height: normal}

.select-style {width: 100%; overflow: hidden; background: url("../images/select-arrow.png") no-repeat 97% 50%; border: 1px solid $border-color; margin-bottom: 20px}
.select-style select {width: 100%; border: none; box-shadow: none; background-color: transparent; background-image: none; -webkit-appearance: none; -moz-appearance: none; appearance: none;  cursor: pointer;}
.select-style select:focus {outline: none; border: none; box-shadow: none}

.input-group input, .input-group textarea, .input-group select {margin:0; border-radius:4px 0 0 4px; border-color: $light; padding: 19px 25px;}
.input-group-append .btn {border-radius:0 4px 4px 0}
.input-group-append .btn.btn-large {line-height:2px; height:62px; padding:15px 25px!important}
.input-group-404 input {height: 62px;}

.input-group .required-error {border: 1px solid #ee2323 !important}
.input-group .required-error,.input-group .required-error + .input-group-btn button , .input-group .required-error + .input-group-btn a.btn, .input-group .required-error+.input-group-append > .btn {border: 1px solid #ee2323 !important}

.input-border-white .input-border-bottom {border-bottom: 1px solid $light; color: $light; border-top: transparent !important;  border-left: transparent !important;  border-right: transparent !important}
.input-border-white .input-border-bottom:focus{background: transparent; border-bottom: 1px solid $light;  color: $light}
.input-border-white .input-border-bottom::-webkit-input-placeholder {color: $light;}
.input-border-white .input-border-bottom::-moz-placeholder {color: $light;} 
.input-border-white .input-border-bottom:-ms-input-placeholder {color: $light;}

.btn .caret{border-top:4px solid}
input.input-bg {background-color: $light-gray; border-radius: 4px; border: 1px solid rgba(0,0,0,.1);padding: 10px 20px; margin-bottom: 15px;}
input.input-bg:focus, textarea.input-bg:focus{border: 0; border: 1px solid rgba(0,0,0,.3);}
textarea.input-bg {background-color: $light-gray; border-radius: 4px; border: 1px solid rgba(0,0,0,.1);padding: 10px 20px; margin-bottom: 15px; min-height: 130px;}
.input-bg::-webkit-input-placeholder {font-weight: 400; font-size: 14px;}
.input-bg::-moz-placeholder {font-weight:400; font-size: 14px;} 
.input-bg:-ms-input-placeholder {font-weight: 400; font-size: 14px;}

#success-subscribe-newsletter {clear: both; margin: 0 15px 15px; text-align: center; border: 1px solid #1a940a; color: #1a940a;  padding: 7px 0; display: none; font-size: 14px}
#success-subscribe-newsletter2 {clear: both; margin: 0 15px 15px; text-align: center; border: 1px solid #1a940a; color: #1a940a;  padding: 7px 0; display: none; font-size: 14px}

.form-results {clear: both; margin: 0 0 15px; text-align: center; padding: 10px 0; display: none; font-size: 14px}

/* separator */
.separator-line-verticle-extra-small {width:1px; height: 8px;}
.separator-line-verticle-small-thick {width:17px; height: 3px;}
.separator-line-verticle-small {width:1px; height: 13px;}
.separator-line-verticle-small-thick2 {width:64px; height: 7px;}
.separator-line-verticle-large {width:1px; height:20px;}
.separator-line-verticle-extra-large {width:1px; height:30px;}
.separator-line-verticle-extra-large2 {width:1px; height:80px;}
.separator-line-verticle-medium-thick-full {width:8px; height: 40px;}
.separator-line-verticle-large-thick {width:109px; height: 11px}
.separator-line-horrizontal-medium-light {width:36px; height: 3px;}
.separator-line-horrizontal-medium-light2 {width:40%; height: 1px;}
.separator-line-horrizontal-medium-light3 {width:18%; height: 1px;}
.separator-line-horrizontal-medium-thick {width:50px; height: 5px;}
.separator-line-horrizontal-full {width:100%; height: 1px;}

/* divider */
.divider-full {width:100%; height: 1px; display: inline-block}
.new-demo {background: var(--base-color); font-size: 10px; font-weight: 600; color: $light; margin-left: 8px; padding: 2px 8px;}

/* opacity */
.opacity-very-light {position: absolute; height: 100%; width: 100%; opacity: 0.2; top:0; left: 0;}
.opacity-light {position: absolute; height: 100%; width: 100%; opacity: 0.3; top:0; left: 0;}
.opacity-extra-medium {position: absolute; height: 100%; width: 100%; opacity: 0.5; top:0; left: 0;}
.opacity-medium {position: absolute; height: 100%; width: 100%; opacity: 0.75; top:0; left: 0;}
.opacity-full {position: absolute; height: 100%; width: 100%; opacity: 0.8; top:0; left: 0;}
.opacity-full-dark {position: absolute; height: 100%; width: 100%; opacity: 0.9; top:0; left: 0;}

.opacity1 {opacity:.1}
.opacity2 {opacity:.2}
.opacity3 {opacity:.3}
.opacity4 {opacity:.4}
.opacity5 {opacity:.5}
.opacity6 {opacity:.6}
.opacity7 {opacity:.7}
.opacity8 {opacity:.8}
.opacity9 {opacity:.9}

/* box-shadow */
.btn-shadow {box-shadow: 0 0 6px rgba(0,0,0,.3);}
.box-shadow-light {box-shadow: 0 0 3px rgba(0,0,0,.1);}
.box-shadow {box-shadow: 0 0 3px rgba(0,0,0, .2);}
.box-shadow-dark {box-shadow: 0 0 4px rgba(0,0,0,.4);}
.box-shadow-large {box-shadow: 0 0 12px rgba(0,0,0,.1);}

/* z-index */
.z-index-1111 {z-index:1111;}
.z-index-111 {z-index:111;}
.z-index-1 {z-index:1;}
.z-index-2 {z-index:2;}
.z-index-3 {z-index:3;}
.z-index-4 {z-index:4;}
.z-index-5 {z-index:5;}
.z-index-0 {z-index:0;}
.z-index-minus1 {z-index:-1;}
.z-index-minus2 {z-index:-2;}

/* verticle align */
.vertical-middle {-webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%); -moz-transform: translateY(-50%); -o-transform: translateY(-50%); position: absolute; top: 50%; left:0; right: 0}
.clear-both{clear:both}
.line-break {display:block;}
.absolute-middle-center {left: 50%; top: 50%; position: absolute; -ms-transform: translateX(-50%) translateY(-50%); -moz-transform: translateX(-50%) translateY(-50%); -webkit-transform: translateX(-50%) translateY(-50%); transform: translateX(-50%) translateY(-50%); -o-transform: translateX(-50%) translateY(-50%);}
.absolute-bottom-center {left: 50%; top: 80%; position: absolute; -ms-transform: translateX(-50%) translateY(-80%); -moz-transform: translateX(-50%) translateY(-80%); -webkit-transform: translateX(-50%) translateY(-80%); transform: translateX(-50%) translateY(-80%); -o-transform: translateX(-50%) translateY(-80%);}

/* google map filters */
.filter-grayscale-50 {-ms-filter: grayscale(50%); filter: grayscale(50%);}
.filter-grayscale-100 {-ms-filter: grayscale(100%); filter: grayscale(100%);}
.filter-invert-50 {-ms-filter: invert(50%); filter: invert(50%);}
.filter-invert-100 {-ms-filter: invert(100%); filter: invert(100%);}
.filter-mix-100 {-ms-filter: grayscale(100%) invert(100%); filter: grayscale(100%) invert(100%);}
.map iframe {height: 619px; vertical-align: bottom; width: 100%;}