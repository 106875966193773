/* ================================
	15. Width
================================ */

.w-1px {width:1px;}
.w-2px {width:2px;}
.w-3px {width:3px;}
.w-4px {width:4px;}
.w-5px {width:5px;}
.w-6px {width:6px;}
.w-7px {width:7px;}
.w-8px {width:8px;}
.w-9px {width:9px;}
.w-10px {width:10px;}
.w-15px {width:15px;}
.w-20px {width:20px;}
.w-25px {width:25px;}
.w-30px {width:30px;}
.w-35px {width:35px;}
.w-40px {width:40px;}
.w-50px {width:50px;}
.w-55px {width:55px;}
.w-60px {width:60px;}
.w-65px {width:65px;}
.w-70px {width:70px;}
.w-75px {width:75px;}
.w-80px {width:80px;}
.w-85px {width:85px;}
.w-90px {width:90px;}
.w-100px {width:100px;}
.w-110px {width:110px;}
.w-120px {width:120px;}
.w-130px {width:130px;}
.w-150px {width:150px;}
.w-180px {width:180px;}
.w-200px {width:200px;}
.w-250px {width:250px;}
.w-300px {width:300px;}
.w-350px {width:350px;}
.w-400px {width:400px;}
.w-450px {width:450px;}
.w-500px {width:500px;}
.w-550px {width:550px;}
.w-600px {width:600px;}
.w-650px {width:650px;}
.w-700px {width:700px;}
.w-750px {width:750px;}
.w-800px {width:800px;}
.w-850px {width:850px;}
.w-900px {width:900px;}
.w-950px {width:950px;}
.w-1000px {width:1000px;}

.w-10 {width:10%;}
.w-12 {width:12%;}
.w-15 {width:15%;}
.w-20 {width:20%;}
.w-25 {width:25%;}
.w-30 {width:30%;}
.w-35 {width:35%;}
.w-40 {width:40%;}
.w-45 {width:45%;}
.w-50 {width:50%;}
.w-55 {width:55%;}
.w-60 {width:60%;}
.w-65 {width:65%;}
.w-70 {width:70%;}
.w-75 {width:75%;}
.w-80 {width:80%;}
.w-85 {width:85%;}
.w-90 {width:90%;}
.w-95 {width:95%;}
.w-100 {width:100%;}
.w-auto {width: auto}