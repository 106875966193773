/* ===================================
	20. Page title
====================================== */

/* page title font size */
.page-title-extra-small h1 {font-size: 12px; line-height: 20px; text-transform: uppercase;}
.page-title-small h1 {font-size: 20px; line-height: 26px}
.page-title-medium h1 {font-size: 32px; line-height: 37px}
.page-title-large h1 {font-size: 46px; line-height: 49px}
.page-title-extra-large h1 {font-size: 55px; line-height: 50px}

/* breadcrumb */
.breadcrumb {background: transparent; border-radius: 0; padding-top: 0; padding-bottom: 0; margin: 0;}
.breadcrumb ul {list-style: none; padding: 0; margin: 0;}
.breadcrumb ul li {display: inline-block; line-height: normal;}
.breadcrumb ul > li:after {content: "\f111"; font-family: Font Awesome\ 5 Free; font-weight: 900; padding: 0 11px 0 14px; display: inline-block; vertical-align: middle; font-size: 5px; opacity: .6;}
.breadcrumb ul > li:last-child:after {border-right: 0; content: ""; padding: 0}