/* ----------------------------------

Elements spécifiques au projet en cours

------------------------------------- */

.bg-gradient-primary {
	background: $fourth;
	background: linear-gradient(0deg, $fourth 0%, $primary 100%);
}

.bg-trame {
	position: relative;
	background:url('../../../images/trame-bg.png'); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.bg-trame-50 {
	position: relative;
	background:url('../../../images/trame-bg.png'); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	&:after {
		background: $fourth;
		content: '';
		height: 50%;
		top: 0;
		position: absolute;
		width: 100%;
	}
}


header {
	nav.navbar {
		&.navbar-default {
			
			ul.nav > li {
				&.simple-dropdown .dropdown-menu {
					background: $dark;
					a {
						color: $primary;
					}
				}
			}
			ul.nav > li > a {
				font-weight: 400;
				font-size: 18px;
				text-transform: none;
				display: inline-block;
			}
		}
		
		.navbar-nav > li.dropdown > .fas {
			display: inline-block;
			transform: translateX(-10px);
		}
		
	}
	
	&.sticky nav.navbar.navbar-default.navbar-fixed-top ul.nav > li > a, &.sticky nav.navbar.navbar-default.navbar-top ul.nav > li > a {
		padding: 26px 0;
	}
	
	a.logo {
		
		img {
			padding-top: 10px;
			padding-bottom: 10px;
			max-height: 70px;
		}
	}
	
	nav.navbar .navbar-nav > li.active > a, nav.navbar.bootsnav ul.nav > li.active > a {
		color: $fourth;
		
		&:hover {
			color: $fourth;
		}
	}
	
	
}

.page-title-large h1 {
	font-size: 40px;
	line-height: 50px;
}

main {
	h1 {font-size:60px; line-height: 75px;}
	h2 {font-size:40px; line-height: 50px;}
	
	&.page {
		
		h3, h4, h5 {
			font-weight: 600;
			margin-bottom: 15px;
		}
		
		h2 {font-size:30px; line-height: 34px; }
		h3 {font-size:24px; line-height: 30px; }
		h4 {font-size:18px; line-height: 24px; }
		
		.title-medium {font-size: 30px; line-height:34px}
		
		p+ul, p+ol {
			margin-top: -1.3em;
		}
	}
	
	
	
	ul:not(.blog-grid) {
		padding-left: 10px;
		li {
			font-size: 15px;
			list-style: none;
			display: list-item;
			vertical-align:middle;
		}
		
		li:before {
			content: "";
			border: 2px #000 solid !important;
			border-radius: 50px;
			margin-top: 10px;
			margin-left: -10px;
			position: absolute;
		}
	}
}



.title-picto-before {
	position: relative;
	padding-left: 50px;
	display: inline-block;
	&:before {
		content: '';
		background: url("../../../images/s-skapane-petit-noir.svg");
		background-repeat: no-repeat;
		height: -webkit-fill-available;
		position: absolute;
		left: 0;
		display: inline-flex;
		top: 0;
		width: 35px;
	}
	
	&.text-white-2 {
		&:before {
			content: '';
			background: url("../../../images/s-skapane-petit-blanc.svg");
			background-repeat: no-repeat;
			height: -webkit-fill-available;
			position: absolute;
			left: 0;
			display: inline-flex;
			top: 0;
			width: 35px;
		}
	}
}

h2.title-picto-before, h3.title-picto-before {
	margin-bottom: 35px !important;
}

.btn.btn-rounded {
	border-radius: 7px;
}


.main-slider {
	.illustration {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		img {
			width: auto;
			max-height: 600px;
		}
	}
	
}

.swiper-button-next.light, .swiper-button-prev.light {
	background: transparent;
	font-size: 30px;
}

.swiper-button-prev {
	left: -30px;
}

.swiper-button-next {
	right: -30px;
}

.straddle-bloc {
	padding: 75px 45px;
}

.counter-box {
	.counter {
		font-size:55px; line-height: 1.5em;
	}
}

.pilier {
	text-align: center;
 	.bulle {
		width: 200px;
		height: 200px;
		border-radius: 50%;
		background-size: contain;
		background-position: center center;
		margin-left: auto;
		margin-right: auto;
 	}	
}

.s-icon {
	width: 38px;
	height: 50px;
	margin-left: auto;
	margin-right: auto;
	-webkit-mask: url("../../../images/s-skapane-petit-noir.svg") no-repeat 100% 100%;
	  mask: url("../../../images/s-skapane-petit-noir.svg") no-repeat 100% 100%;
	  -webkit-mask-size: cover;
	  mask-size: cover;
	  background-color: $secondary;
}

.slider-clients {
	.swiper-slide {
		padding: 15px;
	}
	
	.swiper-button-next-clients.swiper-button-disabled, .swiper-button-prev-clients.swiper-button-disabled {opacity: 0.6;}
	.swiper-button-next-clients, .swiper-button-prev-clients {height: 30px; width: 30px; line-height: 30px; border-radius: 3px; margin: 0; transform: translateY(-50%); -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%); -webkit-transition-duration: 0.3s; -moz-transition-duration: 0.3s; -ms-transition-duration: 0.3s; -o-transition-duration: 0.3s; transition-duration: 0.3s; outline: none;}
	
	.swiper-button-next-clients:after, .swiper-button-prev-clients:after {display: none;}
	.swiper-button-next-clients:hover, .swiper-button-prev-clients:hover {-webkit-transition-duration: 0s; -moz-transition-duration: 0s; -ms-transition-duration: 0s; -o-transition-duration: 0s; transition-duration: 0s;}
	
	.swiper-button-next-clients.light, .swiper-button-prev-clients.light {
		background: transparent;
		font-size: 30px;
		
		position: absolute;
		top: 50%;
		z-index: 10;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	.swiper-button-prev-clients {
		left: 0px;
	}
	
	.swiper-button-next-clients {
		right: 0px;
	}
}

.tab-style3 .nav-tabs li a.nav-link.active, 
.tab-style3 .nav-tabs > li > a.nav-link.active:hover {
	border-bottom: 1px solid $primary;
}

.tab-style3 .nav-tabs li a.nav-link {
	
	&.active, &:hover {
		color: $primary;
	}
}

.boutons-fixes {
	transform: rotate(-90deg);
	background: $dark; 
	color: $light;
	z-index: 1000;
	width: auto;
	padding: 7px;
	display: flex;
	align-items: center;
	justify-content: center;
	
	&:hover {
		background: $primary;
	}
	
	// width: 50px;
	height: 40px;
	width: 40px;
	
	position:fixed; 
	overflow: hidden;
	
	transition-timing-function: ease-in-out; -moz-transition-timing-function: ease-in-out; -webkit-transition-timing-function: ease-in-out; -o-transition-timing-function: ease-in-out; transition-duration: .2s; -moz-transition-duration: .2s; -webkit-transition-duration: .2s; -o-transition-duration: .2s; position:fixed; 
	right: 0px; 
	z-index: 1000; 

	i {
		font-size: 16px; vertical-align: middle; position: relative; top: -1px; color: $dark;
	}
	
	img {
		max-width: 100%;
	}
	
	&.btn-1 {
		top:17%;
		background: transparent;

		&:hover {
			background: $secondary;
		}
	}
	
	&.btn-2 {
		background: transparent;
		top:calc(17% + 40px);
		
		&:hover {
			background: $fourth;
		}
	}
	
	&:hover {
		transition-timing-function: ease-in-out; -moz-transition-timing-function: ease-in-out; -webkit-transition-timing-function: ease-in-out; -o-transition-timing-function: ease-in-out; transition-duration: .2s; -moz-transition-duration: .2s; -webkit-transition-duration: .2s; -o-transition-duration: .2s; right: 0px; background: $primary;
		right: 0px; 
		span {
			opacity: 1; color: $light;
		}
		i {
			color: $light;
		}
	}
	
	&.btn-3 {
		top:calc(17% + 120px);
		right: -32px;
		padding: 0px 20px 0 20px;
		width: auto;
		
		-webkit-border-top-left-radius: 5px;
		-webkit-border-top-right-radius: 5px;
		-moz-border-radius-topleft: 5px;
		-moz-border-radius-topright: 5px;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		
		&:hover {
			right: -32px;
			span {
				color: $dark !important;
			}
		}
	}

}

