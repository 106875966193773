/* ===================================
	04. Background color
====================================== */

.bg-transparent, .background-transparent {background-color: transparent;}
.bg-white, .background-white {background-color:$light;}
.bg-black, .background-black {background-color:$black;}
.bg-extra-dark-gray {background-color:$extra-dark-gray;}
.bg-dark-gray {background-color:$dark-gray;}
.bg-extra-medium-gray {background-color:$extra-medium-gray;}
.bg-very-dark-gray {background-color:$extra-dark-gray;}
.bg-medium-gray {background-color:$medium-gray;}
.bg-extra-light-gray {background-color:$extra-light-gray;}
.bg-medium-light-gray {background-color:$medium-gray;}
.bg-light-gray {background-color:$light-gray;}
.bg-very-light-gray {background-color:$very-light-gray;}
.bg-deep-pink {background-color: $primary;}
.bg-transparent-white {background-color: rgba(255,255,255,0.3); background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 37%, rgba(255,255,255,1) 96%, rgba(255,255,255,1) 100%); background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(37%, rgba(255,255,255,0)), color-stop(96%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,1))); background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 37%, rgba(255,255,255,1) 96%, rgba(255,255,255,1) 100%); background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 37%, rgba(255,255,255,1) 96%, rgba(255,255,255,1) 100%); background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 37%, rgba(255,255,255,1) 96%, rgba(255,255,255,1) 100%); background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 37%, rgba(255,255,255,1) 96%, rgba(255,255,255,1) 100%); filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );}
.bg-transparent-black {background-color: rgba(0,0,0,0); background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 37%, rgba(0,0,0,1) 96%, rgba(0,0,0,1) 100%); background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,0,0,0)), color-stop(37%, rgba(0,0,0,0)), color-stop(96%, rgba(0,0,0,1)), color-stop(100%, rgba(0,0,0,1))); background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 37%, rgba(0,0,0,1) 96%, rgba(0,0,0,1) 100%); background: -o-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 37%, rgba(0,0,0,1) 96%, rgba(0,0,0,1) 100%); background: -ms-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 37%, rgba(0,0,0,1) 96%, rgba(0,0,0,1) 100%); background: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 37%, rgba(0,0,0,1) 96%, rgba(0,0,0,1) 100%); filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );}
.bg-gradient-light-red-light-orange {background-image: linear-gradient(to right top, #ff8e43, #ff7a41, #ff6343, #ff4848, #ff214f)}
.bg-gradient-light-red-light-white{background-image: linear-gradient(50deg, #ff214f 0%, $light 100%);}
.bg-white-opacity {background-color: rgba(255, 255, 255, 0.85);}
.bg-black-opacity {background-color: rgba(0, 0, 0, 0.85);}
.bg-black-opacity-light {background-color: rgba(0, 0, 0, 0.5);}
.bg-deep-pink-opacity {background-color: rgba$primary-opacity-strong;}
.bg-charcoal-gray{background-color: #0e0f10;}
.bg-transparent-white2{background: rgba(255,255,255,.2) !important;}
.bg-transparent-black-very-light {background-color: rgba(0, 0, 0, 0.15) !important;}