/* ============================== 
	14. Position
================================ */

.position-inherit {position:inherit !important}

/* top */
.top-auto {top:auto}
.top-inherit {top: inherit !important;}
.top-0 {top:0 !important;}
.top-1 {top:1px}
.top-2 {top:2px}
.top-3 {top:3px}
.top-4 {top:4px}
.top-5 {top:5px}
.top-6 {top:6px}
.top-7 {top:7px}
.top-8 {top:8px}
.top-9 {top:9px}
.top-10 {top:10px}
.top-15 {top:15px}
.top-20 {top:20px}
.top-25 {top:25px}
.top-30 {top:30px}
.top-35 {top:35px}
.top-40 {top:40px}
.top-45 {top:45px}
.top-50 {top:50px}

.top-minus1 {top:-1px}
.top-minus2 {top:-2px}
.top-minus3 {top:-3px}
.top-minus4 {top:-4px}
.top-minus5 {top:-5px}
.top-minus6 {top:-6px}
.top-minus7 {top:-7px}
.top-minus8 {top:-8px}
.top-minus9 {top:-9px}
.top-minus10 {top:-10px}
.top-minus15 {top:-15px}
.top-minus20 {top:-20px}
.top-minus25 {top:-25px}
.top-minus30 {top:-30px}
.top-minus35 {top:-35px}
.top-minus40 {top:-40px}

/* bottom */
.bottom-auto {bottom:auto}
.bottom-0 {bottom:0}
.bottom-1 {bottom:1px}
.bottom-2 {bottom:2px}
.bottom-3 {bottom:3px}
.bottom-4 {bottom:4px}
.bottom-5 {bottom:5px}
.bottom-6 {bottom:6px}
.bottom-7 {bottom:7px}
.bottom-8 {bottom:8px}
.bottom-9 {bottom:9px}
.bottom-10 {bottom:10px}
.bottom-15 {bottom:15px}
.bottom-20 {bottom:20px}
.bottom-25 {bottom:25px}
.bottom-30 {bottom:30px}
.bottom-35 {bottom:35px}
.bottom-40 {bottom:40px}
.bottom-45 {bottom:45px}
.bottom-50 {bottom:50px !important}

/* left */
.left-auto {left:auto}
.left-0 {left:0}
.left-1 {left:1px}
.left-2 {left:2px}
.left-3 {left:3px}
.left-4 {left:4px}
.left-5 {left:5px}
.left-6 {left:6px}
.left-7 {left:7px}
.left-8 {left:8px}
.left-9 {left:9px}
.left-10 {left:10px}
.left-15 {left:15px}
.left-20 {left:20px}
.left-25 {left:25px}
.left-30 {left:30px}
.left-35 {left:35px}
.left-40 {left:40px}
.left-45 {left:45px}
.left-50 {left:50px}

/* right */
.right-auto {right:auto}
.right-0 {right:0}
.right-1 {right:1px}
.right-2 {right:2px}
.right-3 {right:3px}
.right-4 {right:4px}
.right-5 {right:5px}
.right-6 {right:6px}
.right-7 {right:7px}
.right-8 {right:8px}
.right-9 {right:9px}
.right-10 {right:10px}