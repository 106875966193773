@charset "UTF-8";

/* ----------------------------------

Name: style.css
Version: 4

-------------------------------------

Table of contents
		
	01. Google font
	02. Reset
	03. Typography
	04. Background color
	05. Custom
	06. Button
	07. Border
	08. Aside style
	09. Video
	10. Background image
	11. Margin
	12. Padding
	13. Display and float
	14. Position
	15. Width
	16. Height
	17. Icon
	18. Magnific popup
	19. Header
	20. Page title
	21. Swiper carousel
	22. Grid
	23. Portfolio
	24. Elements
	25. Blog
	26. Footer
	27. Home page
	28. Others
	
	// Ajouts SP
	39. Side panel
*/

@import "component/google-font";
@import "general/variables";
@import "general/reset";
@import "general/typography";
@import "element/background-color";
@import "general/custom";
@import "element/button";
@import "element/border";
@import "element/aside-style";
@import "element/video";
@import "element/background-image";
@import "general/margin";
@import "general/padding";
@import "general/display-and-float";
@import "general/position";
@import "general/width";
@import "general/height";
@import "element/icon";
@import "component/magnific-popup";
@import "element/header";
@import "element/page-title";
@import "component/swiper-carousel";
@import "element/grid";
@import "element/portfolio";
@import "element/elements";
@import "element/blog";
@import "element/footer";
@import "element/home-page";
@import "element/others";
@import "component/side-panel";

@import "responsive";
@import "sp-custom";
@import "sp-project";
@import "sp-responsive-project";