/* ===================================
	23. Portfolio
====================================== */
.portfolio-wrapper .grid-item figure {margin: 0; position: relative; overflow: hidden}
.portfolio-wrapper .grid-item {opacity: 1;overflow: hidden;}
.portfolio-wrapper .grid-item figure img {cursor: pointer; display: block; opacity: 1; width: 100%; transition: all 0.3s ease 0s;}
.portfolio-wrapper .grid-item figcaption {bottom:0; left:0; opacity: 0; position: absolute; transition: all 0.3s ease 0s; -webkit-transition: all 0.3s ease 0s; -moz-transition: all 0.3s ease 0s; -ms-transition: all 0.3s ease 0s; -o-transition: all 0.3s ease 0s; width: 100%; height: 100%; padding:45px; z-index: 1; text-align: center}
.portfolio-wrapper .grid-item figure:hover .portfolio-img {transition: all 0.3s ease 0s; -webkit-transition: all 0.3s ease 0s; -moz-transition: all 0.3s ease 0s; -ms-transition: all 0.3s ease 0s; -o-transition: all 0.3s ease 0s;}
.portfolio-wrapper .grid-item figure .portfolio-img {transition: all 0.3s ease 0s; -webkit-transition: all 0.3s ease 0s; -moz-transition: all 0.3s ease 0s; -ms-transition: all 0.3s ease 0s; -o-transition: all 0.3s ease 0s;}
.portfolio-wrapper .grid-item figure:hover img {opacity: 0.15; transform: scale(1.1, 1.1); -webkit-transform: scale(1.1, 1.1); -moz-transform: scale(1.1, 1.1); -ms-transform: scale(1.1, 1.1); -o-transform: scale(1.1, 1.1); transition: all 0.3s ease 0s; -webkit-transition: all 0.3s ease 0s; -moz-transition: all 0.3s ease 0s; -ms-transition: all 0.3s ease 0s; -o-transition: all 0.3s ease 0s;}
.portfolio-wrapper .grid-item figure:hover figcaption {opacity: 1; transition: all 0.3s ease 0s; -webkit-transition: all 0.3s ease 0s; -moz-transition: all 0.3s ease 0s; -ms-transition: all 0.3s ease 0s; -o-transition: all 0.3s ease 0s;}

/* justified gallery */
.justified-gallery>a>.jg-caption, .justified-gallery>div>.jg-caption, .justified-gallery>figure>.jg-caption {padding:7px 15px; opacity: 0; display: initial !important; bottom: 0;  transition: all 0.3s ease 0s; -webkit-transition: all 0.3s ease 0s; -moz-transition: all 0.3s ease 0s; -ms-transition: all 0.3s ease 0s; -o-transition: all 0.3s ease 0s; font-family: $font-family-base; font-weight: 600; font-size: 11px; text-transform: uppercase}
.caption.caption-visible {opacity: 1; bottom: 0 !important}

/* portfolio hover style */
.grid-item .portfolio-hover-box {display: table-cell; height: 100%;}

/* hover option 1 */
.hover-option1 .grid-item figcaption {height: 100%}
.hover-option1 .grid-item .portfolio-hover-main {transform: none; -webkit-transform: none; -moz-transform: none; -ms-transform: none; -o-transform: none; display: table; height: 100%; top: 0; width: 100%;}

/* hover option 2 */
.hover-option2 .grid-item figcaption {height: 100%}
.hover-option2 .grid-item .portfolio-hover-main {transform: none; -webkit-transform: none; -moz-transform: none; -ms-transform: none; -o-transform: none; display: table; height: 100%; top: 0; width: 100%;}
.hover-option2 .grid-item .portfolio-hover-content {bottom:-15px; transition: all 0.3s ease 0s; -webkit-transition: all 0.3s ease 0s; -moz-transition: all 0.3s ease 0s; -ms-transition: all 0.3s ease 0s; -o-transition: all 0.3s ease 0s;}
.hover-option2 .grid-item:hover .portfolio-hover-content {bottom:0;}
.hover-option2 .separator-line-horrizontal-medium-light2 {opacity:.5}
.hover-option2 .grid-item figure:hover img {-webkit-filter: blur(5px); -moz-filter: blur(5px); -ms-filter: blur(5px); -o-filter: blur(5px); filter: blur(5px); opacity: 0.10; transform: scale(1.1, 1.1); -webkit-transform: scale(1.1, 1.1); -moz-transform: scale(1.1, 1.1); -ms-transform: scale(1.1, 1.1); -o-transform: scale(1.1, 1.1); transition: all 0.3s ease 0s; -webkit-transition: all 0.3s ease 0s; -moz-transition: all 0.3s ease 0s; -ms-transition: all 0.3s ease 0s; -o-transition: all 0.3s ease 0s;}
.container .hover-option2 .grid-item figcaption {padding: 30px;}

/* hover option 3 */
.hover-option3 .grid-item .slide-hover-box {position: absolute; width: 100%; height: 100%;}
.hover-option3 .grid-item .opacity-medium {opacity: 0; transition: all 0.3s ease 0s; -webkit-transition: all 0.3s ease 0s; -moz-transition: all 0.3s ease 0s; -ms-transition: all 0.3s ease 0s; -o-transition: all 0.3s ease 0s;}
.hover-option3 .grid-item .slide-hover-box:hover .opacity-medium {opacity: 0.50; transition: all 0.3s ease 0s; -webkit-transition: all 0.3s ease 0s; -moz-transition: all 0.3s ease 0s; -ms-transition: all 0.3s ease 0s; -o-transition: all 0.3s ease 0s;}
.hover-option3 .grid-item figure {bottom:130px; z-index: 5; width: 100%; overflow: visible}
.hover-option3 .grid-item figure h6 {position: relative; bottom: -70px; transition: all 0.3s ease 0s; -webkit-transition: all 0.3s ease 0s; -moz-transition: all 0.3s ease 0s; -ms-transition: all 0.3s ease 0s; -o-transition: all 0.3s ease 0s;}
.hover-option3 .grid-item .slide-hover-box:hover h6 {bottom: 0}
.hover-option3 .grid-item figure p {bottom: -40px; opacity: 0; position: relative; transition: all 0.3s ease 0s; -webkit-transition: all 0.3s ease 0s; -moz-transition: all 0.3s ease 0s; -ms-transition: all 0.3s ease 0s; -o-transition: all 0.3s ease 0s;}
.hover-option3 .grid-item .slide-hover-box:hover p {opacity: .6; bottom: 0;}
.hover-option3 .grid-item figure figcaption {left: 0; right: 0; opacity: 1; padding: 0 60px; height: auto; text-align: left}
.hover-option3 .grid-item .separator-line-horrizontal-medium-light2 {transition: all 0.3s ease 0s; -webkit-transition: all 0.3s ease 0s; -moz-transition: all 0.3s ease 0s; -ms-transition: all 0.3s ease 0s; -o-transition: all 0.3s ease 0s; width: 20%;}
.hover-option3 .grid-item .slide-hover-box:hover .separator-line-horrizontal-medium-light2 {width: 60%; transition: all 0.3s ease 0s; -webkit-transition: all 0.3s ease 0s; -moz-transition: all 0.3s ease 0s; -ms-transition: all 0.3s ease 0s; -o-transition: all 0.3s ease 0s;}

/* hover option 4 */
.hover-option4 .grid-item figcaption {height: 100%}
.hover-option4 .grid-item .portfolio-hover-main {transform: none; display: table; height: 100%; top: 0; width: 100%;}
.hover-option4 .grid-item figure:hover img {opacity: 0.08; transform: scale(1, 1); transition: all 0.2s ease 0s; -webkit-transition: all 0.2s ease 0s; -moz-transition: all 0.2s ease 0s; -ms-transition: all 0.2s ease 0s; -o-transition: all 0.2s ease 0s; filter: blur(4px); -webkit-filter: blur(4px); -moz-filter: blur(4px); -ms-filter: blur(4px); -o-filter: blur(4px);}
.hover-option4 .grid-item .portfolio-hover-content {bottom:-15px; transition: all 0.2s ease 0s; -webkit-transition: all 0.2s ease 0s; -moz-transition: all 0.2s ease 0s; -ms-transition: all 0.2s ease 0s; -o-transition: all 0.2s ease 0s;}
.hover-option4 .grid-item:hover .portfolio-hover-content {bottom:0;}
.hover-option4 .swiper-slide.grid-item figcaption {position: absolute; top: 0; width:100%; opacity: 0}
.hover-option4 .swiper-slide.grid-item:hover figcaption {opacity: 1}
.container .hover-option4 .grid-item figcaption {padding: 30px;}

/* hover option 5 */
.hover-option5 .grid-item figcaption {height: 100%; padding: 20px}
.hover-option5 .grid-item .portfolio-hover-main {transform: none; -webkit-transform: none; -moz-transform: none; -ms-transform: none; -o-transform: none; display: table; height: 100%; top: 0; width: 100%;}
.hover-option5 .grid-item .portfolio-hover-box {padding: 20px;}
.hover-option5 .grid-item:hover .portfolio-hover-box {background: rgba(0,0,0,.8)}
.hover-option5 .grid-item .separator-line-horrizontal-medium-light2 {-webkit-transition: all 0.2s ease 0s; -moz-transition: all 0.2s ease 0s; -ms-transition: all 0.2s ease 0s; -o-transition: all 0.2s ease 0s; transition: all 0.2s ease 0s; top:5px; width:100px}
.hover-option5 .grid-item:hover .separator-line-horrizontal-medium-light2 {transform: translateY(-30px); -webkit-transform: translateY(-30px); -moz-transform: translateY(-30px); -ms-transform: translateY(-30px); -o-transform: translateY(-30px);}
.hover-option5 .grid-item figure:hover img {opacity: .8}
.container .hover-option5 .grid-item .portfolio-hover-box {padding: 10px;}

/* hover option 6 */
.hover-option6 .grid-item figcaption {height: 100%; position: relative; opacity: 1; padding:35px 10px; background: #fff}
.hover-option6 .grid-item .portfolio-hover-main {transform: none; -webkit-transform: none; -moz-transform: none; -ms-transform: none; -o-transform: none; display: table; height: 100%; top: 0; width: 100%;}
.hover-option6 .grid-item .portfolio-icon {transition: all 0.3s ease 0s; position: absolute; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%); position: absolute; top: 55%; left:0; right: 0; opacity: 0}
.hover-option6 .grid-item figure:hover .portfolio-icon {opacity: 1; top: 50%;}
.hover-option6 .grid-item .portfolio-icon a {border-radius: 50%; border:2px solid transparent; background: #fff; margin: 0 7px; height: 46px; width: 46px; display: inline-block; -webkit-transition: all 0.2s ease 0s; -moz-transition: all 0.2s ease 0s; -ms-transition: all 0.2s ease 0s; -o-transition: all 0.2s ease 0s; transition: all 0.2s ease 0s;}
.hover-option6 .grid-item .portfolio-icon i.fa-link {font-size:20px; position: relative; top: 11px}
.hover-option6 .grid-item .portfolio-icon i.fa-search {font-size:16px; position: relative; top: 10px}
.hover-option6 .grid-item .portfolio-icon a:hover, .hover-option6 .grid-item .portfolio-icon a:hover i {background: transparent; color: #fff;}
.hover-option6 .grid-item .portfolio-icon a:hover {background: transparent; border:2px solid #fff; color: #fff; transform: scale(1.1, 1.1); -webkit-transform: scale(1.1, 1.1); -moz-transform: scale(1.1, 1.1); -ms-transform: scale(1.1, 1.1); -o-transform: scale(1.1, 1.1);}
.hover-option6 .grid-item figure:hover img {cursor: default; opacity: .15; transform: scale(1, 1); -webkit-transform: scale(1, 1); -moz-transform: scale(1, 1); -ms-transform: scale(1, 1); -o-transform: scale(1, 1);}
.hover-option6 .grid-item figure:hover figcaption {cursor: default;}
.hover-option6 .grid-item figure:hover .portfolio-img {transition: all 0.3s ease 0s; -webkit-transition: all 0.3s ease 0s; -moz-transition: all 0.3s ease 0s; -ms-transition: all 0.3s ease 0s; -o-transition: all 0.3s ease 0s;}
.container .hover-option6 .grid-item figcaption {padding: 25px 10px;}

/* hover option 7 */
.hover-option7 .grid-item figcaption {height: auto;  position: absolute; opacity: 0; padding:32px; bottom: -101px}
.hover-option7 .grid-item figure:hover figcaption {bottom: 0}
.hover-option7 .grid-item .portfolio-hover-main {transform: none; -webkit-transform: none; -moz-transform: none; -ms-transform: none; -o-transform: none; display: table; height: 100%; top: 0; width: 100%;}
.hover-option7 .grid-item figure img {top: 0; position: relative}
.hover-option7 .grid-item figure:hover img {cursor: pointer; opacity: 1; transform: scale(1,1); -webkit-transform: scale(1,1); -moz-transform: scale(1,1); -ms-transform: scale(1,1); -o-transform: scale(1,1); top:-101px}
.hover-option7 .grid-item figure:hover figcaption {cursor: pointer;}
.hover-option7 .grid-item figure:hover .portfolio-img {transition: all 0.3s ease 0s; -webkit-transition: all 0.3s ease 0s; -moz-transition: all 0.3s ease 0s; -ms-transition: all 0.3s ease 0s; -o-transition: all 0.3s ease 0s; background: transparent}
.container .hover-option7 .grid-item figcaption {padding:20px;}
.container .hover-option7 .grid-item figure:hover img {top:-81px;}

/* hover option 8 */
.hover-option8 .grid-item figure figcaption {opacity: 0; padding: 0; transition: all 0.2s ease 0s; -webkit-transition: all 0.2s ease 0s; -moz-transition: all 0.2s ease 0s; -ms-transition: all 0.2s ease 0s; -o-transition: all 0.2s ease 0s;} 
.hover-option8 .grid-item figure:hover figcaption {opacity: 1; cursor: pointer} 
.hover-option8 .grid-item .portfolio-hover-main {display: table; height: 100%; top: 0; transform: none; -webkit-transform: none; -moz-transform: none; -ms-transform: none; -o-transform: none; width: 100%;} 
.hover-option8 .grid-item .portfolio-icon {position: absolute; right: 55px; top: 51px; transition: all 0.1s ease 0s; -webkit-transition: all 0.1s ease 0s; -moz-transition: all 0.1s ease 0s; -ms-transition: all 0.1s ease 0s; -o-transition: all 0.1s ease 0s;}
.hover-option8 .grid-item figure:hover figcaption {padding:25px; transition: all 0.2s ease 0s; -webkit-transition: all 0.2s ease 0s; -moz-transition: all 0.2s ease 0s; -ms-transition: all 0.2s ease 0s; -o-transition: all 0.2s ease 0s; box-shadow: 0 0 4px rgba(0,0,0,.4);}
.hover-option8 .grid-item figure:hover figcaption .portfolio-hover-main {background: #fff}
.hover-option8 .grid-item figure:hover img {opacity: 1; filter: blur(4px);-webkit-filter: blur(4px); -moz-filter: blur(4px); -ms-filter: blur(4px); -o-filter: blur(4px);}
.container .hover-option8 .grid-item figure:hover figcaption {padding: 20px;}
.container .hover-option8 .grid-item .portfolio-icon {top: 30px; right: 35px;}

/* hover option 10 */
.hover-option10 .grid-item * {-webkit-transition: all 0.3s ease 0s; -moz-transition: all 0.3s ease 0s; -ms-transition: all 0.3s ease 0s; -o-transition: all 0.3s ease 0s; transition: all 0.3s ease 0s;}
.hover-option10 .grid-item figure .portfolio-img img{transform: scale(1.05,1.05); -webkit-transform: scale(1.05,1.05); -moz-transform: scale(1.05,1.05); -ms-transform: scale(1.05,1.05); -o-transform: scale(1.05,1.05);}
.hover-option10 .grid-item figure:hover .portfolio-img img{transform: scale(1,1); -webkit-transform: scale(1,1); -moz-transform: scale(1,1); -ms-transform: scale(1,1); -o-transform: scale(1,1); opacity: .15}
.hover-option10 .grid-item figure figcaption {padding: 0;}
.hover-option10 .grid-item figure .portfolio-hover-main {display: table; width: 100%; height: 100%; padding: 50px}
.hover-option10 .grid-item figure .portfolio-icon {margin-bottom: 30px; position: relative; -webkit-transform: translateY(-100%); -ms-transform: translateY(-100%); transform: translateY(-100%);}
.hover-option10 .grid-item figure .portfolio-hover-content{position: relative; -webkit-transform: translateY(100%); -ms-transform: translateY(100%); -moz-transform: translateY(100%); transform: translateY(100%);}
.hover-option10 .grid-item figure:hover .portfolio-icon, .hover-option10 .grid-item figure:hover .portfolio-hover-content {-webkit-transform: translateY(0); -ms-transform: translateY(0); -moz-transform: translateY(0); transform: translateY(0);}
.hover-option10 .grid-item .portfolio-icon a {margin: 0 7px; height: 50px; width: 50px; display: inline-block; border-radius: 100%; border: 2px solid rgba(255,255,255,1); box-sizing: border-box; vertical-align: top; text-align:center; color: #fff}
.hover-option10 .grid-item .portfolio-icon a:hover {color: $extra-dark-gray; background: #fff; border-radius: 5px;}
.hover-option10 .grid-item .portfolio-icon a:hover i {color: $extra-dark-gray;;}
.hover-option10 .grid-item .portfolio-icon i.fa-link {font-size:22px; position: relative; top: 14px}
.hover-option10 .grid-item .portfolio-icon i.fa-search {font-size:20px; position: relative; top: 13px}
.hover-option10 .grid-item .portfolio-icon i.ti-zoom-in {font-size:20px; position: relative; top: 13px}
.container .hover-option10 .grid-item .portfolio-icon i.fa-link {font-size:16px; position: relative; top: 10px}
.container .hover-option10 .grid-item .portfolio-icon i.fa-search {font-size:14px; position: relative; top: 8px}
.container .hover-option10 .grid-item .portfolio-icon a {width: 45px; height: 45px;}
.container .hover-option10 .grid-item figure .portfolio-hover-main {padding: 30px;}

/* hover option 11 */
.hover-option11 .grid-item figcaption {height: 100%}
.hover-option11 .grid-item .portfolio-hover-main {transform: none; display: table; height: 100%; top: 0; width: 100%;}
.hover-option11 .grid-item figure:hover img {opacity: 0.05; transform: scale(1, 1); transition: all 0.2s ease 0s; -webkit-transition: all 0.2s ease 0s; -moz-transition: all 0.2s ease 0s; -ms-transition: all 0.2s ease 0s; -o-transition: all 0.2s ease 0s; filter: blur(4px); -webkit-filter: blur(4px); -moz-filter: blur(4px); -ms-filter: blur(4px); -o-filter: blur(4px);}
.hover-option11 .grid-item .portfolio-hover-content {bottom:-15px; transition: all 0.2s ease 0s; -webkit-transition: all 0.2s ease 0s; -moz-transition: all 0.2s ease 0s; -ms-transition: all 0.2s ease 0s; -o-transition: all 0.2s ease 0s;}
.hover-option11 .grid-item:hover .portfolio-hover-content {bottom:0;}
.hover-option11 .swiper-slide.grid-item figcaption {position: absolute; top: 0; width:100%; opacity: 0}
.hover-option11 .swiper-slide.grid-item:hover figcaption {opacity: 1}
.hover-option11 .grid-item .portfolio-item-title{transition: all 0.2s ease 0s; -webkit-transition: all 0.2s ease 0s; -moz-transition: all 0.2s ease 0s; -o-transition: all 0.2s ease 0s; -ms-transition: all 0.2s ease 0s;}
.hover-option11 .grid-item > a:hover .portfolio-item-title {color: #fff;}
.hover-option11 .grid-item .portfolio-img {margin-bottom: 33px; transition: all 0.2s ease 0s; -webkit-transition: all 0.2s ease 0s; -moz-transition: all 0.2s ease 0s; -o-transition: all 0.2s ease 0s; -ms-transition: all 0.2s ease 0s;}
.hover-option11 .grid-item .portfolio-img img {box-shadow: 0 0 8px rgba(0,0,0,1);}
.hover-option11 .grid-item:hover .portfolio-img img {opacity: .6; transition: all 0.2s ease 0s; -webkit-transition: all 0.2s ease 0s; -moz-transition: all 0.2s ease 0s; -o-transition: all 0.2s ease 0s; -ms-transition: all 0.2s ease 0s;}
.hover-option11 .grid-item > a{display: block; position: relative;}
.hover-option11 .grid-item > a:hover .portfolio-img{box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.2); transform: translateY(-10px);}

/* portfolio filter tab style 1 */
.portfolio-filter-tab-1 li {margin: 0 25px; display: inline-block; float: none}
.portfolio-filter-tab-1 li a {background: transparent; border: none; padding:0 0 2px 0; display: inline; color:$medium-gray; margin: 0; border-bottom: 1px solid transparent;}
.portfolio-filter-tab-1 li a:hover, .portfolio-filter-tab-1 li a:focus {background: transparent; border-bottom: 1px solid $extra-dark-gray; color:$extra-dark-gray}
.portfolio-filter-tab-1 li.active a {background: transparent !important; border-bottom: 1px solid $extra-dark-gray; color: $extra-dark-gray;}

/* portfolio filter tab style 2 */
.portfolio-filter-tab-2 li.nav-item{display: inline-block; float: none;}
.portfolio-filter-tab-2 li.nav-item a.nav-link{border: none; border-radius: 50px !important; padding: 7px 24px; margin-right: 4px; border-color: transparent !important;}
.portfolio-filter-tab-2 li.nav-item a.nav-link:hover, .portfolio-filter-tab-2 li.nav-item > a.nav-link:focus{background: rgba(144,144,144,0.22); color: #fff; border-color: transparent !important;}
.portfolio-filter-tab-2.nav-tabs li.nav-item.active a.nav-link, .portfolio-filter-tab-2.nav-tabs li.nav-item.active a.nav-link:focus, .portfolio-filter-tab-2.nav-tabs li.nav-item.active a.nav-link:hover{border: none; background: rgba(144,144,144,0.22); color: #fff; border: none;}

/* blog and portfolio next pagination */
.blog-nav-link {padding: 30px 0; display: inline-block; vertical-align: middle; font-size: 16px; position: relative}
.blog-nav-link-prev .blog-nav-icon, .blog-nav-link-next .blog-nav-icon {top: 50%; transform: translateY(-50%); -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%); position: absolute}
.blog-nav-link-next {padding-right: 50px}
.blog-nav-link-next .blog-nav-icon{right: 0}
.blog-nav-link-prev {padding-left: 50px}
.blog-nav-link-prev .blog-nav-icon {left: 0}