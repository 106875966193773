/* ----------------------------------

Elements responsive spécifiques au projet en cours

------------------------------------- */

// SUPÉRIEUR de 1600px
@media (min-width: 1600px) {
	
	.container {
		max-width: 1400px;
	}
	
}

// SUPÉRIEUR de 1199px
@media (max-width: 1600px) and (min-width: 1199px) {

	.container {
		max-width: 1200px;
	}
}

// Inférieur à 1600px : XL
@media (max-width: 1600px) {
}

// Inférieur à 1199px : LG
@media (max-width: 1199px) {

	main {
		h2 {font-size:30px; line-height: 40px;}
	}
}

// Inférieur à 992px : MD
@media (max-width: 992px) {
	
	// menu normal
	.navbar-nav li > a, nav.navbar.navbar-default ul.nav > li > a,
	header .navbar-nav li > a {
		padding: 12px 40px 11px;
	}
	
	// menu sticky
	header.sticky nav.navbar.navbar-default.navbar-fixed-top ul.nav > li > a, header.sticky nav.navbar.navbar-default.navbar-top ul.nav > li > a {
		padding: 12px 40px 11px;
	}
	
	nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu > li > a {
		color: $primary;
	}
	
	nav.navbar.bootsnav ul.nav > li > a:hover {
		color: $primary;
	}
	
	// Réglages des paddings standards
	$standard-padding: 40px;
	
	.minimal-paddings {padding: calc(#{$standard-padding} /2) 0; overflow: hidden;}
	.standard-paddings {padding: $standard-padding 0; overflow: hidden;}
	.standard-padding-top {padding-top: $standard-padding; padding-bottom: 0; overflow: hidden;}
	.standard-padding-half-top {padding-top: calc(#{$standard-padding} /2); padding-bottom: 0; overflow: hidden;}
	.standard-padding-bottom {padding-top: 0; padding-bottom: $standard-padding; overflow: hidden;}
	.standard-padding-half-bottom {padding-top: 0; padding-bottom: calc(#{$standard-padding} /2); overflow: hidden;}
	.standard-padding-top-half-bottom {padding-top: $standard-padding; padding-bottom: calc(#{$standard-padding} /2); overflow: hidden;}
	.standard-padding-bottom-half-top {padding-top: calc(#{$standard-padding} /2); padding-bottom: $standard-padding; overflow: hidden;}
	
	.one-fourth-screen {
		height: 100vh;
		
		.illustration-slider {
			max-width: 50%;
			width: auto;
			text-align: center;
			
			margin-top: 40px;
			margin-left: auto;
			margin-right: auto;
		}
	}
	
	// .one-second-screen {
	// 	height: auto;
	// }
	// 
	.main-slider {
		.illustration {
			img {
				width: auto;
				max-height: 400px;
			}
		}
		
	}
	
	.counter-feature-box-1 {
		h5 {
			font-size: 40px;
		}
		
		.fa-caret-right {
			display: block;
			transform: rotate(90deg);
			margin-left: 0;
			font-size: 40px;
			margin-top: 10px;
		}
	}
	
	.slider-clients {
		.swiper-button-prev-clients {
			left: 15px;
		}
		
		.swiper-button-next-clients {
			right: 15px;
		}
	}
	
	.tab-style3 .nav-tabs li {
		margin-bottom: 15px;
	}
	
	.arrow-right:after{
		right: initial;
		bottom: 4px; 
		top: initial;
		margin-top: initial;
		transform: rotate(90deg);
		left: 50%; 
		margin-left: 13px; 
	}
	
	.boutons-fixes {
		
		&.btn-1 {
			top:50%;
		}
		
		&.btn-2 {
			background: transparent;
			top:calc(50% + 40px);
		}
		
		&.btn-3 {
			top:calc(50% + 120px);
		}
	
	}
	
	footer {
		text-align: center;
	}
	
	

}

// Inférieur à 767px : SM
@media (max-width: 767px) {
	.straddle-bloc {
		padding-top: 30px;
		padding-bottom: 30px;
		padding-left: 20px;
		padding-right: 20px;
	}
	
	main {
		h1 {font-size:40px; line-height: 55px;}
		h2 {font-size:24px; line-height: 34px;}
		
		&.page {
			
			h3, h4, h5 {
				font-weight: 600;
				margin-bottom: 15px;
			}
			
			h2 {font-size:24px; line-height: 34px;}
			h3 {font-size:22px; line-height: 26px; }
			h4 {font-size:18px; line-height: 24px; }
			
			.title-medium {font-size:24px; line-height: 34px;}
			
			p+ul, p+ol {
				margin-top: -1.3em;
			}
		}
	}
	
	.page-title-large h1 {
		font-size: 30px;
		line-height: 40px;
	}
	
	
	
	.main-slider {
		.illustration {
			img {
				width: auto;
				max-height: 200px;
			}
		}
		
		.one-second-screen {height:auto;}
		
	}
	
	.one-second-screen {height:250px;}
	
	.small-screen {height:230px;}
	
	
}

@media (max-width: 640px) {
}
	
// Inférieur à 575px : XS
@media (max-width: 575px) {
	
}

// Inférieur à 320px : XXS
@media screen and (max-width: 320px){
	.two-column {
		column-count: 1;
		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid;
		
		li {
			-webkit-margin-before: 0;
			-webkit-margin-after: 0;
			display: inline;
			
			a {
				display: block;
				margin-bottom: 10px;
			}
		}
	}
}