/* ===================================
	22. Grid
====================================== */

.blog-content, .blog-wrapper, .blog-wrapper .grid-item, .blog-wrapper .grid-sizer {transition-timing-function: none; -moz-transition-timing-function: none; -webkit-transition-timing-function: none; -o-transition-timing-function: none; -ms-transition-timing-function: none; transition-duration: 0s; -moz-transition-duration: 0s; -webkit-transition-duration: 0s; -o-transition-duration: 0s; -ms-transition-duration: 0s;}

/* grid loading */
@keyframes spin { to { -webkit-transform: rotate(360deg); -ms-transform: rotate(360deg); transform: rotate(360deg); } }
.grid-loading, .grid-loading-white {overflow: hidden; position: relative; opacity: 1 !important; transition: all 0s ease-in-out !important; -moz-transition: all 0s ease-in-out !important; -webkit-transition: all 0s ease-in-out !important; -ms-transition: all 0s ease-in-out !important; -o-transition: all 0s ease-in-out !important; z-index: 99;}
.grid-loading::after, .grid-loading-white::after {background: none; content: "" !important; position: absolute; top: 100px !important; left: calc(50% - 25px) !important; width: 50px !important; height: 50px !important; opacity: 1 !important; right: inherit !important; z-index: 9; text-align: center; border: 3px solid rgba(23, 23, 23, 0.2); border-top-color: rgba(23, 23, 23, 0.7); border-radius: 50%; animation: spin 1s linear infinite; -webkit-animation: spin 1s linear infinite;}
.grid-loading:before {content: ""; position: absolute; right: 2px; top: 1px; width: calc(100% - 4px); height: calc(100% - 2px); border-radius: inherit; z-index: 1;}
.grid-loading .grid-item, .grid-loading-white .grid-item {visibility: hidden;}
.grid-loading li.grid-item, .grid-loading-white li.grid-item {overflow: hidden;}
.grid-loading-white::after {background: none; content: "" !important; border: 3px solid rgba(255, 255, 255, 0.2); border-top-color: rgba(255, 255, 255, 0.7);}

.blog-post-images img, .portfolio-image img {width: 100%;}
.grid {list-style: none; padding: 0; margin: 0;}
.grid-sizer {padding: 0 !important; margin: 0 !important;}

/* gutter size */
.grid.gutter-very-small li {padding: 3px 3px;}
.grid.gutter-small li {padding:5px 5px;}
.grid.gutter-medium li {padding:7px 7px;}
.grid.gutter-large li {padding:10px 10px;}
.grid.gutter-extra-large li {padding:15px;}
.grid.gutter-double-extra-large li {padding:20px;}
.grid.gutter-triple-extra-large li {padding:30px;}

/* grid 6 column */
.grid.grid-6col li {width: 16.67%; list-style: none;}
.grid.grid-6col li.grid-item-double {width: 33.33%;}

/* grid 5 column */
.grid.grid-5col li {width: 20%; list-style: none;}
.grid.grid-5col li.grid-item-double {width: 40%;}

/* grid 4 column */
.grid.grid-4col li {width: 25%; list-style: none;}
.grid.grid-4col li.grid-item-double {width: 50%;}

/* grid 3 column */
.grid.grid-3col li {width: 33.33%; float: left; list-style: none;}
.grid.grid-3col li.grid-item-double {width: 66.67%;}

/* grid 2 column */
.grid.grid-2col li {width: 50%; list-style: none;}
.grid.grid-2col li.grid-item-double {width: 100%;}

/* grid 1 column */
.grid.grid-1col li {width: 100%;  list-style: none;}

/* grid filter */
.grid-filter li {display: inline-block; float: none; padding: 0 20px; border: 0; position: relative;}
.grid-filter li:first-child {padding-left: 0;}
.grid-filter li:last-child {padding-right: 0;}
.grid-filter li a {border: 0; border-bottom: 1px solid; border-color: transparent; color:$extra-medium-gray; padding-bottom: 2px;}
.grid-filter > li.active > a, .grid-filter > li.active > a:focus, .grid-filter > li.active > a:hover, .grid-filter > li > a:hover {border: 0; color: inherit; border-bottom: 1px solid; color: $extra-dark-gray}
