/* ===================================
	02. Reset
====================================== */
html {font-size: $body-text-size;}
body {font-family: $font-family-base; -webkit-font-smoothing: antialiased; font-smoothing: antialiased; font-size:$body-text-size; color:$body-text-color; font-weight: 400;line-height: 24px;}
body, html {height: 100%; -moz-osx-font-smoothing: grayscale;}
a, a:active, a:focus {color: $links; text-decoration: none;}
a:hover, a:active{color: $links-hover; text-decoration: none;}
a:focus, a:active, button:focus, button:active,.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {outline: none; box-shadow: none;}
img {max-width:100%; height:auto;}
video {background-size: cover; display: table-cell; vertical-align: middle; width: 100%;}
input, textarea, select{border: 1px solid $border-color; font-size: 14px;  padding: 8px 15px; width: 100%; margin: 0 0 20px 0; max-width: 100%; resize: none; color: inherit;}
input[type="submit"] {width: auto}
input[type="button"], input[type="text"], input[type="email"], input[type="search"], input[type="password"], textarea, input[type="submit"] {-webkit-appearance: none; outline: none;}
input:focus, textarea:focus{border-color: $primary !important; outline: none;}
input[type="button"]:focus, input:focus, textarea:focus, select:focus, .form-control:focus, .btn.focus, .btn:focus{outline: none; box-shadow: none;}
select::-ms-expand{display:none;}
iframe {border: 0;}
p {margin: 0 0 25px}
b, strong {font-weight: 700;}
.last-paragraph-no-margin p:last-of-type {margin-bottom:0}
.alt-font strong {font-weight: 700}
ul, ol, dl {list-style-position: outside; margin-bottom: 25px}
*{transition-timing-function: ease-in-out; -ms-transition-timing-function: ease-in-out; -moz-transition-timing-function: ease-in-out; -webkit-transition-timing-function: ease-in-out; -o-transition-timing-function: ease-in-out; transition-duration: .2s; -ms-transition-duration: .2s; -moz-transition-duration: .2s; -webkit-transition-duration: .2s; -o-transition-duration: .2s;}
*:hover{transition-timing-function: ease-in-out; -ms-transition-timing-function: ease-in-out; -moz-transition-timing-function: ease-in-out; -webkit-transition-timing-function: ease-in-out; -o-transition-timing-function: ease-in-out; transition-duration: .2s; -ms-transition-duration: .2s; -moz-transition-duration: .2s; -webkit-transition-duration: .2s; -o-transition-duration: .2s;}
.no-transition *, .swiper-container *, .mfp-container *, .portfolio-wrapper *, .parallax, .rev-slider *, .header-searchbar *, .header-social-icon *, .dropdropdown-menu * {transition-timing-function: none; -moz-transition-timing-function: none; -webkit-transition-timing-function: none; -o-transition-timing-function: none; -ms-transition-timing-function: none; transition-duration: 0s; -moz-transition-duration: 0s; -webkit-transition-duration: 0s; -o-transition-duration: 0s; -ms-transition-duration: 0s;}
::selection {color: $black; background:$light-gray;}
::-moz-selection {color:$black; background:$light-gray;}
::-webkit-input-placeholder {color: $dark; text-overflow: ellipsis;}
::-moz-placeholder {color: $dark; text-overflow: ellipsis; opacity:1;} 
:-ms-input-placeholder {color: $dark; text-overflow: ellipsis; opacity:1;}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {padding-right: 15px; padding-left: 15px;}
.row {margin-right: -15px; margin-left: -15px;}
.row>* {padding-right: 15px; padding-left: 15px;}
.row.g-0 {margin-right: 0; margin-left: 0;}
.row.g-0>* {padding-right: 0; padding-left: 0;}
header [class^="container-"] [class^="col-"], header [class^="container-"] [class*=" col-"] {position: relative; padding-left: 15px; padding-right: 15px;}
.swiper-container { z-index: 1; }

@media (prefers-reduced-motion:no-preference) {
	:root {scroll-behavior: auto}
}