// Couleurs de base
$body-bg-color: #fff;
$body-text-color: #290F33;
$body-text-size: 15px;

$primary:       #9CF7FE;
$secondary:     #FEEB52;
$third:     #FFB400;
$fourth:     #43dbff;

$light: #fff;
$black: #000;
$dark: #290F33;

// Nuances de gris
$extra-dark-gray: #232323;
$dark-gray: #626262;
$extra-medium-gray: #757575;
$medium-gray: #939393;
$light-gray:    #d6d5d5;
$extra-light-gray: #b7b7b7;
$very-light-gray: #ededed;

:root {
	--bg-light: $light;
	--bg-dark: $dark;
	--base-color: $primary;
}

// Opacités
$primary-opacity:       rgba(156, 247, 254, 0.5);
$primary-opacity-light:       rgba(156, 247, 254, 0.3);
$primary-opacity-strong:       rgba(156, 247, 254, 0.8);

$secondary-opacity:     rgba(255, 180, 0, 0.5);
$secondary-opacity-light:     rgba(255, 180, 0, 0.3);
$secondary-opacity-strong:     rgba(255, 180, 0, 0.8);

// Liens
$links: $dark;
$links-hover: $black;

$footer-links: $dark;
$footer-links-hover: $light;

$theme_elements: #fff;

// tables
$table-border: #000;
$blog-content-bg: #f0f0f0;

// Navigation
$nav-container: #290F33;
$nav-text-color: #9CF7FE;

// Paddings standards
$standard-padding: 70px;

// border color
$border-color:          #dee2e6;

// font
$font-family-base:    'Quicksand', sans-serif;
$font-family-second: 	'Roboto', sans-serif;