/* ===================================
	03. Typography
====================================== */

/* font family */
.alt-font {font-family: $font-family-second;}
.main-font {font-family: $font-family-base, sans-serif;}

/* heading */
h1, h2, h3, h4, h5, h6 {margin:0 0 25px; padding:0; letter-spacing: 0; font-weight: 400;}
h1 {font-size:60px; line-height: 75px;}
h2 {font-size:40px; line-height:60px}
h3 {font-size:48px; line-height:54px}
h4 {font-size:40px; line-height:46px}
h5 {font-size:32px; line-height:40px}
h6 {font-size:25px; line-height:30px}

/* text size */
.text-extra-small {font-size:11px; line-height:14px}
.text-small {font-size:14px; line-height:20px}
.text-medium {font-size:16px; line-height:23px}
.text-large {font-size:18px; line-height:26px}
.text-large-xl {font-size:20px; line-height:30px}
.text-extra-large {font-size: 24px; line-height:28px}

.title-medium {font-size: 30px; line-height:34px}
.title-large {font-size: 55px; line-height:65px}
.title-extra-large {font-size: 130px; line-height:120px}

/* text color */
.text-white-2, .btn.text-white-2 {color:$light}
.text-black, .btn.text-black {color:$black}
.text-extra-dark-gray, .btn.text-extra-dark-gray {color:$extra-dark-gray}
.text-dark-gray, .btn.text-dark-gray {color:$dark-gray}
.text-extra-medium-gray, .btn.text-extra-medium-gray {color:$extra-medium-gray}
.text-medium-gray, .btn.text-medium-gray {color:$medium-gray}
.text-extra-light-gray, .btn.text-extra-light-gray {color:$extra-light-gray}
.text-light-gray, .btn.text-light-gray {color:$light-gray}
.text-very-light-gray, .btn.text-very-light-gray {color:$very-light-gray}
.text-deep-pink, .btn.text-deep-pink {color: $primary}

/* dropcap */
.first-letter {float: left; font-size: 50px; line-height: auto; margin: 0 20px 0 0; text-align: center; padding: 10px 0; font-weight: 600}
.first-letter-big {float: left; font-size: 110px; line-height: 110px; margin: 0 20px 0 0; padding:0 8px;  text-align: center; font-weight: 600; position: relative;}
.first-letter-big:before {position: absolute; border-bottom: 1px solid; content: ""; display: block;  width: 100%; top: 55%; left: 0}
.first-letter-block {font-size: 30px; height: 55px; line-height: 22px; padding: 15px 0; width: 55px; font-weight: 500; margin-top: 5px}
.first-letter-block-round {border-radius:6px; border:1px solid;}
.first-letter-round {border-radius: 50%; font-size:35px; padding: 15px; width: 65px; height: 65px; line-height: 32px;}

/* blockquote */
blockquote {padding: 20px 30px}
blockquote  p {font-size: 18px; line-height: 30px; margin-bottom: 17px !important; font-weight: 400}
blockquote {border-left: 2px solid; padding: 15px 40px; margin: 45px 0}
blockquote footer {color: $medium-gray;}
blockquote .small:before, blockquote footer:before, blockquote small:before {content: '\2014 \00A0';}
.blog-image blockquote {padding: 60px; border: 0; margin: 0;}
.blog-image blockquote h6:before {content:"\e67f"; font-family:'themify'; font-size: 40px; top:5px; position: relative; margin-right: 12px; color: $primary;}

/* text link hover color */
a.text-link-white-2, a.text-link-white-2 i {color:$light}
a.text-link-white-2:hover, a.text-link-white-2:hover i, a.text-link-white-2:focus, a.text-link-white-2:focus i {color:$primary;}
a.text-link-black, a.text-link-black i {color:$black}
a.text-link-dark-gray {color:$dark-gray}
a.text-link-dark-gray:hover, .text-link-dark-gray:focus {color:$dark-gray}
a.text-link-extra-dark-gray {color:$extra-dark-gray}
a.text-link-extra-dark-gray:hover, a.text-link-extra-dark-gray:focus {color:$extra-dark-gray}
a.text-link-deep-pink, a.text-link-deep-pink i {color:$primary;}
a.text-link-deep-pink:hover, a.text-link-deep-pink:hover i, a.text-link-deep-pink:focus, a.text-link-deep-pink:focus i {color:$light}

/* hover color */
a.text-white-2-hover:hover, a.text-white-2-hover:focus {color:$light !important;}
a.text-black-hover:hover, a.text-black-hover:focus {color:$black !important;}
a.text-deep-pink-hover:hover, a.text-deep-pink-hover:focus {color:$primary !important;}
a.text-extra-dark-gray-hover:hover {color:$extra-dark-gray !important;}
a.text-dark-gray-hover:hover {color:$dark-gray !important;}
a.text-extra-medium-gray-hover:hover {color:$extra-medium-gray !important;}
a.text-medium-gray-hover:hover {color:$medium-gray !important;}
a.text-extra-light-gray-hover:hover {color:$extra-light-gray !important;}
a.text-light-gray-hover:hover {color:$light-gray !important;}
a.text-very-light-gray-hover:hover {color:$very-light-gray !important;}

/* letter spacing */
.no-letter-spacing {letter-spacing:0px}
.letter-spacing-1 {letter-spacing:1px}
.letter-spacing-2 {letter-spacing:2px}
.letter-spacing-3 {letter-spacing:3px}
.letter-spacing-4 {letter-spacing:4px}
.letter-spacing-5 {letter-spacing:5px}
.letter-spacing-6 {letter-spacing:6px}
.letter-spacing-7 {letter-spacing:7px}
.letter-spacing-8 {letter-spacing:8px}
.letter-spacing-9 {letter-spacing:9px}
.letter-spacing-10 {letter-spacing:10px}
.letter-spacing-minus-1 {letter-spacing:-1px}
.letter-spacing-minus-2 {letter-spacing:-2px}
.letter-spacing-minus-3 {letter-spacing:-3px}
.letter-spacing-minus-4 {letter-spacing:-4px}
.letter-spacing-minus-5 {letter-spacing:-5px}
.letter-spacing-minus-6 {letter-spacing:-6px}
.letter-spacing-minus-7 {letter-spacing:-7px}
.letter-spacing-minus-8 {letter-spacing:-8px}
.letter-spacing-minus-9 {letter-spacing:-9px}
.letter-spacing-minus-10 {letter-spacing:-10px}

/* font weight */
.font-weight-100 {font-weight:100}
.font-weight-200 {font-weight:200}
.font-weight-300 {font-weight:300}
.font-weight-400 {font-weight:400}
.font-weight-500 {font-weight:500}
.font-weight-600 {font-weight:600}
.font-weight-700 {font-weight:700}
.font-weight-800 {font-weight:800}
.font-weight-900 {font-weight:900}

/* text property */
.text-transform-unset {text-transform:unset}
.text-decoration-underline {text-decoration:underline;}
.text-decoration-line-through {text-decoration:line-through;}
.line-height-unset {line-height: unset}
.line-height-normal {line-height: normal}
.line-height-none {line-height:0}
.word-wrap {word-wrap: break-word}
.text-nowrap{white-space: nowrap;}
.text-transform-none {text-transform:none}
.title-line-through {padding: 0 15px}
.text-middle-line {position: relative; white-space: nowrap}
.text-outside-line {position: relative}
.text-outside-line::before {border-top: 1px solid; content: ""; height: 0; left: auto; position: absolute; right: -60px; top: 50%; width: 40px; opacity: .4;}
.text-outside-line::after {border-top: 1px solid; content: ""; height: 0; left: auto; position: absolute; left: -60px; top: 50%; width: 40px; opacity: .4;}
.text-outside-line-left {position: relative}
.text-outside-line-left::before {border-top: 1px solid; content: ""; height: 0; left: auto; position: absolute; left: -100%; top: 50%; width: 90%; opacity: .2;}
.text-startside-line {position: relative; padding-left: 35px}
.text-startside-line:before {border-top: 1px solid; content: ""; height: 0; left: auto; position: absolute; left: 0; top: 45%; width: 25px; opacity: .7}
.text-outside-line-full {position: relative; display: inline-block}
.text-outside-line-full::before {content: ""; position: absolute; top: 50%; height: 1px; width: 99%; display: block; border-bottom:1px solid; right: 100%; margin-right: 25px; opacity: .15}
.text-outside-line-full::after {content: ""; position: absolute; top: 50%; height: 1px; width: 99%; display: block; border-bottom:1px solid; left: 100%; margin-left: 25px; opacity: .15}
.text-middle-line:before {border-bottom: 1px solid; position: absolute; content: ""; width: 100%; top: 53%; opacity: 0.35}
.text-bottom-line {width: 1px; border-top: 30px solid; margin-left: auto; margin-right: auto;}
.text-middle-line-deep-pink:before {border-bottom: 1px solid var(--base-color); position: absolute; content: ""; width: 100%; margin-top: 5px; margin-left: -20px; top: 50%; -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%); transform: translateY(-50%);}
.text-decoration-line-through-black,.text-decoration-line-through-deep-pink {position: relative;}
.text-decoration-line-through-deep-pink:before {background: var(--base-color);}
.text-decoration-line-through-black:before {background: #000;}
.text-decoration-line-through-black::before,.text-decoration-line-through-deep-pink:before {content: ""; height: 1px; left: -10px; position: absolute; top: 52%; width: calc(100% + 20px);-webkit-transform: translateY(-52%); -moz-transform: translateY(-52%); -ms-transform: translateY(-52%); -o-transform: translateY(-52%); transform: translateY(-52%);}

/* line height */
.line-height-10 {line-height:10px}
.line-height-13 {line-height:13px}
.line-height-18 {line-height:18px}
.line-height-20 {line-height:20px}
.line-height-24 {line-height:24px}
.line-height-22 {line-height:22px}
.line-height-26 {line-height:26px}
.line-height-28 {line-height:28px}
.line-height-30 {line-height:30px}
.line-height-35 {line-height:35px}
.line-height-40 {line-height:40px}
.line-height-45 {line-height:45px}
.line-height-50 {line-height:50px}
.line-height-55 {line-height:55px}
.line-height-60 {line-height:60px}
.line-height-65 {line-height:65px}
.line-height-70 {line-height:70px}
.line-height-75 {line-height:75px}
.line-height-80 {line-height:80px}
.line-height-85 {line-height:85px}
.line-height-90 {line-height:90px}
.line-height-95 {line-height:95px}
.line-height-100 {line-height:100px}
.line-height-110 {line-height:110px}
.line-height-120 {line-height:120px}