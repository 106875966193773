/* ===================================
   25. Blog
====================================== */
.blog-grid .grid-item a {position: relative; z-index: 11;}
.blog-image a > img {width: 100%}

/* blog post style 1 */
.blog-post.blog-post-style1 .blog-post-images {background: $extra-dark-gray}
.blog-post.blog-post-style1 .blog-post-images img {width: 100%;}
.blog-post.blog-post-style1:hover .blog-post-images img {opacity: 0.5; transform: scale(1.2, 1.2); transition: all 0.3s ease 0s;}

/* blog post style 2 */
.blog-post.blog-post-style2 .author img{width: 30px; display: inline-block; vertical-align: middle;}
.blog-post.blog-post-style2 .author span{max-width: calc(100% - 40px); vertical-align: middle; display: inline-block;}

/* blog post style 3 */
.blog-post-style3 .grid-item .blog-post * {transition-timing-function: ease-in-out; -moz-transition-timing-function: ease-in-out; -ms-transition-timing-function: ease-in-out; -webkit-transition-timing-function: ease-in-out; -o-transition-timing-function: ease-in-out; transition-duration: .3s; -moz-transition-duration: .3s; -ms-transition-duration: .3s; -webkit-transition-duration: .3s; -o-transition-duration: .3s;}
.blog-post-style3 .grid-item .blog-post .author img {width: 25px;}
.blog-post-style3 .grid-item .blog-post .blog-post-images a {display: block;}
.blog-post-style3 .grid-item .blog-post .blog-post-images .blog-hover-icon {position: absolute; height: 100%; width: 100%; background: rgba(0,0,0,0.5); top: 0; left: 0; text-align: center; opacity: 0;}
.blog-post-style3 .grid-item .blog-post:hover .blog-post-images .blog-hover-icon {opacity: 1}
.blog-post-style3 .grid-item .blog-post .blog-post-images .blog-hover-icon span {top: 35%; position: relative; font-size: 48px; color: $light; opacity: 0}
.blog-post-style3 .grid-item .blog-post:hover .blog-post-images .blog-hover-icon span {top: 40%; opacity: 1;}

/* blog post style 4 */
.blog-post-style4 .blog-grid .grid-item figure {position: relative; overflow: hidden;}
.blog-post-style4 figure {position: relative;}
.blog-post-style4 figure figcaption {padding: 45px; bottom: 0px; position: absolute; background: rgba(0,0,0,.85); width: 100%; transition: ease-in-out 0.5s; -webkit-transition: ease-in-out 0.5s; -moz-transition: ease-in-out 0.5s; -ms-transition: ease-in-out 0.5s; -o-transition: ease-in-out 0.5s;}
.blog-post-style4 .grid-item figure figcaption p {margin-bottom:0; transition: none; -webkit-transition: none; -moz-transition: none; -ms-transition: none; -o-transition: none; display: none}
.blog-post-style4 .blog-grid .grid-item .blog-img img {cursor: pointer; display: block; width: 100%; opacity: .9; transition: all 0.3s ease 0s; -webkit-transition: all 0.3s ease 0s; -moz-transition: all 0.3s ease 0s; -ms-transition: all 0.3s ease 0s; -o-transition: all 0.3s ease 0s;}
.blog-post-style4 .blog-grid .grid-item:hover .blog-img img {transform: scale(1.1); -webkit-transform: scale(1.1); -moz-transform: scale(1.1); -ms-transform: scale(1.1); -o-transform: scale(1.1); opacity: .5; transition: all 0.3s ease 0s; -webkit-transition: all 0.3s ease 0s; -moz-transition: all 0.3s ease 0s; -ms-transition: all 0.3s ease 0s; -o-transition: all 0.3s ease 0s;}
.blog-post-style4 .blog-grid a {position: relative; z-index:auto;}
.container .blog-post-style4 figure figcaption {padding: 32px;}

/* blog post style 5 */
.blog-post-style5 .blog-post .blog-post-images {height: 100%; position: relative}
.blog-post-style5 .blog-post .blog-categories {position: absolute; bottom:0; left: 0; z-index: 25; padding: 10px 20px 8px}

/* blog post style 6 */
.blog-post-style6:hover {background-color: $light;}
.blog-post-style6:hover .text-white-2 {color: $black !important}
.blog-post-style6:hover .author:before {background-color: $black !important}
.blog-post-style6 .author:before {width: 100px; height: 1px; background-color: $light; opacity: 0.25; position: absolute; top: 0; left: 0; display: inline-block; content: ""}

/* blog post style 7 */
.blog-post-style7:hover {background-color: #1c1c1c !important; color: $light}
.blog-post-style7:hover a {color: $light}
.blog-post-style7:hover .author:before {background-color: $light; opacity: 0.8;}
.blog-post-style7 .author:before {width: 100px; height: 1px; background-color: $medium-gray !important; opacity: 0.25; position: absolute; top: 0; left: 0; display: inline-block; content: ""}

/* pagination */
.pagination {border-radius: 0; padding: 0; margin: 0}
.pagination ul {display: inline-block; *display: inline; *zoom: 1; margin-left: 0; margin-bottom: 0; padding: 0;}
.pagination li {display: inline;}
.pagination a {float: left; padding: 0 18px; line-height: 40px; text-decoration: none; border: 1px solid #ddd; border-left-width: 0; background: $light}
.pagination a:hover {background-color: $extra-dark-gray; color: $light}
.pagination .active a {background-color: #f5f5f5;}
.pagination .active a {color: #999999; cursor: default;}
.pagination .disabled span, .pagination .disabled a, .pagination .disabled a:hover {color: #999999; background-color: transparent; cursor: default;}
.pagination li:first-child a {border-left-width: 1px;}
.pagination-centered {text-align: center;}
.pagination-right {text-align: right;}

/* blog details page */
/* author */
.about-author-img {float: left; max-width: 100px; margin-right: 35px; margin-bottom: 15px;}
.about-author-text {top: -5px}
.author-sharing a {margin-right: 15px; color: $dark-gray}
.author-sharing a:hover {color: $black}
.alignnone {margin: 5px 30px 40px 0;}
.aligncenter, div.aligncenter {display: block; margin: 5px auto 5px auto;}
.alignright {float:right; margin: 5px 0 30px 40px;}
.alignleft {float: left; margin: 5px 40px 30px 0;}
a img.alignright {float: right; margin: 5px 0 40px 30px;}
a img.alignnone {margin: 5px 20px 20px 0;}
a img.alignleft {float: left; margin: 5px 20px 20px 0;}
a img.aligncenter {display: block; margin-left: auto; margin-right: auto}
.wp-caption {background: #f7f7f7; padding:17px; text-align: center; border: 1px solid rgba(0,0,0,.1); max-width: 50%;}
.wp-caption figcaption {padding: 18px 25px 0 25px;}
.wp-caption img {width: 100%}
.wp-caption.alignnone {margin: 5px 30px 40px 0;}
.wp-caption.alignleft {margin: 5px 40px 30px 0;}
.wp-caption.alignright {margin: 5px 0 40px 30px;}
.wp-caption p.wp-caption-text {font-size: 11px; line-height: 17px; margin: 0; padding: 0 4px 5px;}
.screen-reader-text {clip: rect(1px, 1px, 1px, 1px); position: absolute !important; height: 1px; width: 1px; overflow: hidden;}
.screen-reader-text:focus {background-color: #f1f1f1; border-radius: 3px; box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6); clip: auto !important; color: #21759b; display: block; font-size: 14px; font-size: 0.875rem; font-weight: bold; height: auto; left: 5px; line-height: normal; padding: 15px 23px 14px; text-decoration: none; top: 5px; width: auto; z-index: 1}

/* list style 6 */
.blog-comment {list-style: none; margin: 0; padding: 0;}
.blog-comment li {padding: 50px 0; border-bottom: 1px solid $very-light-gray; position: relative}
.blog-comment li .btn-reply {float: right; border: 1px solid $very-light-gray; padding: 0 8px; font-size: 10px; font-weight: 600; position: relative;}
.blog-comment > li:first-child {padding-top: 0}
.blog-comment li:last-child {border-bottom: none; padding-bottom: 0}
.blog-comment li ul.child-comment {margin-left: 40px; list-style: none}
.blog-comment li ul.child-comment li {border: none; padding-bottom: 0; border-top: 1px solid $very-light-gray; margin-top: 50px}