/* ===================================
	21. Swiper carousel
====================================== */

.swiper-container.swiper-blog *, .hover-option4 .swiper-container *{transition-timing-function: ease-in-out; -ms-transition-timing-function: ease-in-out; -moz-transition-timing-function: ease-in-out; -webkit-transition-timing-function: ease-in-out; -o-transition-timing-function: ease-in-out; transition-duration: .2s; -ms-transition-duration: .2s; -moz-transition-duration: .2s; -webkit-transition-duration: .2s; -o-transition-duration: .2s;}
.swiper-container {overflow: hidden; position: relative; width: 100%; }
.swiper-pagination-bullet-active {background: $extra-dark-gray}
.swiper-pagination {width: 100%}
.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction, .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {bottom: 35px;}
.swiper-pagination-bullet {height: 9px; width: 9px; outline: none;}

.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {margin: 0 4px}
.swiper-slider-second {text-align: center;}
.swiper-pagination-bottom .swiper-wrapper {margin-bottom: 60px;}
.swiper-pagination-bottom.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-bottom.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bottom .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-bottom .swiper-pagination-custom, .swiper-pagination-bottom .swiper-pagination-fraction {bottom: 0px;}

/* swiper bottom scrollbar full */
.swiper-bottom-scrollbar-full.swiper-container {height:100%; margin: 0 auto; overflow: hidden; position: absolute; top: 0;  transition: all 0.3s ease-in-out 0.7s; -webkit-transition: all 0.3s ease-in-out 0.7s; -moz-transition: all 0.3s ease-in-out 0.7s; -ms-transition: all 0.3s ease-in-out 0.7s; -o-transition: all 0.3s ease-in-out 0.7s; width: 100%;}
.swiper-bottom-scrollbar-full .swiper-scrollbar {left: 0; opacity: 1; position: absolute; width: 100%; bottom:0; border-radius: 0; height: 2px}
.swiper-bottom-scrollbar-full .swiper-scrollbar-drag {border-radius: 0; background: var(--base-color)}
.swiper-bottom-scrollbar-full .swiper-scrollbar {background: rgba(255,255,255,.2)}
.swiper-bottom-scrollbar-full .swiper-slide img {max-height: 450px; transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; opacity: .8; -webkit-filter: grayscale(1); filter: grayscale(1); -webkit-filter: grayscale(1); -moz-filter: grayscale(1); -ms-filter: grayscale(1); -o-filter: grayscale(1);}
.swiper-bottom-scrollbar-full .swiper-slide:hover img {opacity: 1; -webkit-filter: grayscale(0); -moz-filter: grayscale(0); -ms-filter: grayscale(0); -o-filter: grayscale(0); filter: grayscale(0); filter: contrast(130%); -webkit-filter:contrast(130%); -moz-filter: contrast(130%); -ms-filter: contrast(130%); -o-filter: contrast(130%); max-height: 500px;}
.swiper-bottom-scrollbar-full .swiper-slide .hover-title-box {visibility: hidden;}
.swiper-bottom-scrollbar-full .swiper-slide:hover {z-index: 1;}
.swiper-bottom-scrollbar-full .swiper-slide:hover .hover-title-box {right:-160px; opacity: 1;visibility: visible;}
.swiper-bottom-scrollbar-full .bottom-text {position: absolute; bottom: 50px; opacity: 0; transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out;}
.swiper-bottom-scrollbar-full .swiper-slide:hover .bottom-text {opacity: .7; letter-spacing: 5px; left: 4px;}

/* swiper vertical pagination */
.swiper-vertical-pagination {width: 100%; height: 100%; position: absolute;}
.swiper-vertical-pagination .swiper-slide {text-align: center;  background: #fff; display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex; -webkit-box-pack: center; -ms-flex-pack: center; -webkit-justify-content: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center; -webkit-align-items: center; align-items: center;}
.swiper-vertical-pagination >.swiper-pagination-bullets {right: 60px;}
.swiper-vertical-pagination .swiper-pagination {width: auto}  
.swiper-vertical-pagination .swiper-slide .swiper-bottom-content {opacity: 0; transform: translate3d(0px, 150px, 0px); -webkit-transform: translate3d(0px, 150px, 0px); -moz-transform: translate3d(0px, 150px, 0px); -ms-transform: translate3d(0px, 150px, 0px); -o-transform: translate3d(0px, 150px, 0px); transition: all 0.3s ease-in-out 0s; -webkit-transition: all 0.3s ease-in-out 0s; -moz-transition: all 0.3s ease-in-out 0s; -ms-transition: all 0.3s ease-in-out 0s; -o-transition: all 0.3s ease-in-out 0s;}
.swiper-vertical-pagination .swiper-slide-active .swiper-bottom-content {opacity: 1; transform: translate3d(0px, 0px, 0px); -webkit-transform: translate3d(0px, 0px, 0px); -moz-transform: translate3d(0px, 0px, 0px); -ms-transform: translate3d(0px, 0px, 0px); -o-transform: translate3d(0px, 0px, 0px); transition: all 0.3s ease-in-out 0.3s; -webkit-transition: all 0.3s ease-in-out 0.3s; -moz-transition: all 0.3s ease-in-out 0.3s; -ms-transition: all 0.3s ease-in-out 0.3s; -o-transition: all 0.3s ease-in-out 0.3s;}
.swiper-vertical-pagination .box-arrow {position: absolute; right:-45px; width: 90px; height: 90px;}

/* swiper auto width */
.swiper-auto-slide .swiper-slide {width: 60%;}
.swiper-auto-slide.swiper-container {height: calc(100% - 200px); top: 100px; margin: 0 auto; overflow: visible; position: absolute; transform: scale(1); -webkit-transform: scale(1); -moz-transform: scale(1); -ms-transform: scale(1); -o-transform: scale(1); transition: all 0.3s ease-in-out 0.7s; -webkit-transition: all 0.3s ease-in-out 0.7s; -moz-transition: all 0.3s ease-in-out 0.7s; -ms-transition: all 0.3s ease-in-out 0.7s; -o-transition: all 0.3s ease-in-out 0.7s; width: 100%;}
.swiper-auto-slide .swiper-scrollbar {left: 20%; opacity: 1; position: absolute; width: 60%; bottom:-50px; border-radius: 0; height: 1px}
.swiper-auto-slide .swiper-scrollbar-drag {border-radius: 0; background: transparent;}
.swiper-auto-slide .swiper-scrollbar-drag:before{cursor:move; position: absolute; content: ""; background: var(--base-color); width: 13px; height: 13px; border-radius: 100%; top:-6px; left: calc(50% - 13px);}
.swiper-auto-slide .swiper-slide {filter: contrast(130%); -webkit-filter:contrast(130%); -moz-filter: contrast(130%); -ms-filter: contrast(130%); -o-filter: contrast(130%); filter: grayscale(1); -webkit-filter: grayscale(1); -moz-filter: grayscale(1); -ms-filter: grayscale(1); -o-filter: grayscale(1); transition: all 0.3s ease-in-out 0s; -webkit-transition: all 0.3s ease-in-out 0s; -moz-transition: all 0.3s ease-in-out 0s; -ms-transition: all 0.3s ease-in-out 0s; -o-transition: all 0.3s ease-in-out 0s;}
.swiper-auto-slide .swiper-slide.swiper-slide-active {filter: contrast(0); -webkit-filter:contrast(0); -moz-filter: contrast(0); -ms-filter: contrast(0); -o-filter: contrast(0); filter: grayscale(0); -webkit-filter: grayscale(0); -moz-filter: grayscale(0); -ms-filter: grayscale(0); -o-filter: grayscale(0); transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out;}
.swiper-auto-slide .swiper-slide .absolute-middle-center h2 {opacity: 0; margin-bottom: 10px; transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; transform: translateY(-60px); -webkit-transform: translateY(-60px); -moz-transform: translateY(-60px); -ms-transform: translateY(-60px); -o-transform: translateY(-60px);}
.swiper-auto-slide .swiper-slide.swiper-slide-active .absolute-middle-center h2 {opacity: 1; transition: all 0.3s ease-in-out 0.7s; -webkit-transition: all 0.3s ease-in-out 0.7s; -moz-transition: all 0.3s ease-in-out 0.7s; -ms-transition: all 0.3s ease-in-out 0.7s; -o-transition: all 0.3s ease-in-out 0.7s; transform: translateY(0px); -webkit-transform: translateY(0px); -moz-transform: translateY(0px); -ms-transform: translateY(0px); -o-transform: translateY(0px);}
.swiper-auto-slide .swiper-slide span {opacity: 0; transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out;}
.swiper-auto-slide .swiper-slide.swiper-slide-active span {opacity: 1; transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out;}

/* slider navigation */
.swiper-next.swiper-button-disabled, .swiper-prev.swiper-button-disabled {opacity: 0.6;}
.swiper-button-next, .swiper-button-prev {height: 30px; width: 30px; line-height: 30px; border-radius: 3px; margin: 0; transform: translateY(-50%); -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%); -webkit-transition-duration: 0.3s; -moz-transition-duration: 0.3s; -ms-transition-duration: 0.3s; -o-transition-duration: 0.3s; transition-duration: 0.3s; outline: none;}
.swiper-button-next.large, .swiper-button-prev.large {height: 62px; width: 62px; line-height: 62px; font-size: 18px;}
.swiper-button-next:after, .swiper-button-prev:after {display: none;}
.swiper-button-next:hover, .swiper-button-prev:hover {-webkit-transition-duration: 0s; -moz-transition-duration: 0s; -ms-transition-duration: 0s; -o-transition-duration: 0s; transition-duration: 0s;}
.swiper-button-next {right: 20px;}
.swiper-button-prev {left: 20px;}
.slider-navigation.swiper-button-next, .slider-navigation.swiper-button-prev {background-color: rgba(23,23,23,.5); color: #fff;}
.slider-navigation.swiper-button-next:hover, .slider-navigation.swiper-button-prev:hover {background-color: #fff; color: $extra-dark-gray;}
.swiper-button-next.dark, .swiper-button-prev.dark {background-color: rgba(23,23,23,.5); color: #fff;}
.swiper-button-next.light, .swiper-button-prev.light {background-color: #fff; color: $extra-dark-gray;}
.swiper-button-next.arrow-dark, .swiper-button-prev.arrow-dark {background-color: transparent; color: rgba(23,23,23,1);}
.swiper-button-next.arrow-dark:hover, .swiper-button-prev.arrow-dark:hover {background-color: transparent; color: rgba(23,23,23,.5);}
.swiper-button-next.arrow-light, .swiper-button-prev.arrow-light {background-color: transparent; color: rgba(255,255,255,1);}
.swiper-button-next.arrow-light:hover, .swiper-button-prev.arrow-light:hover {background-color: transparent; color: rgba(255,255,255,.5);}
.swiper-button-next.arrow-gray, .swiper-button-prev.arrow-gray {background-color: transparent; color: rgba(255,255,255,.5);}
.swiper-button-next.arrow-gray:hover, .swiper-button-prev.arrow-gray:hover {background-color: transparent; color: rgba(255,255,255,1);}
.slider-navigation.swiper-button-next {right: 40px;}
.slider-navigation.swiper-button-prev {left: 40px;}
.arrow-big.swiper-button-next.swiper-button-black-highlight i, .arrow-big.swiper-button-prev.swiper-button-black-highlight i {font-size: 18px;}
.arrow-big.swiper-button-next.swiper-button-black-highlight {margin-right: 0}
.arrow-big.swiper-button-prev.swiper-button-black-highlight {margin-left: 0;}
.arrow-big.swiper-button-next, .arrow-big.swiper-button-prev {width: 50px; height: 50px; font-size: 30px;}
.square-arrow {border-radius: 0 !important;}

/* blog swiper */
.swiper-blog .swiper-slide .slide-content {opacity: 0; padding-left: 15px; padding-right: 15px; transform: translateY(100px); -webkit-transform: translateY(100px); -moz-transform: translateY(100px); -ms-transform: translateY(100px); -o-transform: translateY(100px);}
.swiper-blog .swiper-slide.swiper-slide-active .slide-content {opacity: 1; transition-delay: 0.3s; -webkit-transition-delay: 0.3s; -moz-transition-delay: 0.3s; -ms-transition-delay: 0.3s; -o-transition-delay: 0.3s; transform: translateY(0); -webkit-transform: translateY(0); -moz-transform: translateY(0); -ms-transform: translateY(0); -o-transform: translateY(0);}

/* next prev button style 2 */
.swiper-next-style2,.swiper-prev-style2 {position: absolute; bottom: -60px; cursor: pointer; text-transform: uppercase; outline: none;}
.swiper-next-style2 {right: 15%;}
.swiper-prev-style2 {left: 15%;}

/* portfolio next prev button */
.swiper-portfolio-prev, .swiper-portfolio-next {position: absolute; top: 50%; z-index: 1; background: #fff; color: $extra-dark-gray; padding: 15px; font-size: 18px; cursor: pointer; transform: translateY(-50%); -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%);}
.swiper-portfolio-prev.swiper-button-disabled, .swiper-portfolio-next.swiper-button-disabled {opacity: 0; visibility: hidden;}
.swiper-portfolio-prev {left: 0;}
.swiper-portfolio-next {right: 0;}
.swiper-portfolio-prev, .swiper-portfolio-next {width: auto; height: auto; border-radius: 0;}

/* next prev button style 4 */
.navigation-area {position: absolute; bottom: 0; display: inline-block; width: 40px; height: 82px;}
.swiper-button-prev.swiper-prev-style4, .swiper-button-next.swiper-next-style4 {background-image: none; background: var(--base-color); color: #fff; text-align: center; line-height: 40px; height: 40px; width: 40px; display: block; float: left; position: relative; left: auto; right: auto; top: 0; margin: 0 auto; border-radius: 0; transform: translateY(0); -webkit-transform: translateY(0); -moz-transform: translateY(0); -ms-transform: translateY(0); -o-transform: translateY(0);}
.swiper-button-next.swiper-next-style4 {margin-bottom:1px}

/* dot pagination white */
.swiper-pagination-white .swiper-pagination-bullet {background:#fff}
.swiper-pagination-white .swiper-pagination-bullet-active {background:#fff}
.swiper-pagination-square-bottom.swiper-pagination-bullets {bottom: -50px}

/* square pagination */
.swiper-pagination-square .swiper-pagination-bullet {height:4px; width:30px; border-radius: 0}

/* square pagination */
.white-move .swiper-slide {cursor: url("../images/icon-move-light.png"), move;}
.black-move .swiper-slide {cursor: url("../images/icon-move-black.png"), move;}
.white-move.swiper-vertical-pagination .swiper-slide {cursor: url("../images/icon-move-light-vertical.png"), move;}
.black-move.swiper-vertical-pagination .swiper-slide {cursor: url("../images/icon-move-black-vertical.png"), move;}

/* number pagination */
.swiper-number {left: auto !important; right: 0!important; width: 35px !important; text-align: right!important; top:38% !important; bottom: inherit !important;}
.swiper-number .swiper-pagination-bullet {background: transparent; color: $medium-gray; display: block; font-size: 20px; height: 45px; line-height: 20px; opacity: .7; padding: 14px 0 0; text-align: center; width: 23px; border-radius: 0; margin: 0 0 5px 0;}
.swiper-number .swiper-pagination-bullet.swiper-pagination-bullet-active{border-bottom: 1px solid rgba(0,0,0,.5); opacity: 1;}
.swiper-number .swiper-pagination-bullet-active {color:#000; background: transparent;}

/* revolution slider bullet */ 
.tp-bullet {border-radius: 100%; background: transparent; border: 1px solid #fff;}
.tp-bullet.selected, .tp-bullet:hover {background: #fff}
.hermes .tp-bullet {box-shadow: 0 0 0 0 #fff; height:10px; width: 10px; border: 2px solid #fff;}
.hermes .tp-bullet::after {box-shadow: 0 0 0 0 #fff inset;}

/* slider height */
.slider-half-screen {height:765px}
.slider-medium-screen {height:898px}

/* cursor */ 
.cursor-default {cursor: default}

/* down scroll */
.down-section {position: absolute; width: 100%; bottom:50px; left: 0; right: 0}
.scroll-down-section {position: absolute; width: 100%; bottom: -50px; left: 0; right: 0; z-index: 1;}
.scroll-down-section div {height: 100px;}