/* ===================================
	18. Magnific popup
====================================== */

.mfp-bg, .my-mfp-zoom-in.mfp-ready.mfp-bg, .my-mfp-slide-bottom.mfp-ready.mfp-bg {background: #000; opacity: 0.93;}
.mfp-bg, .mfp-wrap {z-index: 10007}
#popup-form {cursor:default;}
.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {color:#fff; background: transparent}
.mfp-iframe-scaler iframe {box-shadow: none;}
.mfp-iframe-holder .mfp-content {max-width: 1020px;}
.mfp-close,.mfp-close:active {opacity: 1; background-color:transparent; color: #fff; right: 30px; top: 30px;}
.modal-popup-main .mfp-close, .modal-popup-main .mfp-close:active {right: 0; top: 0;}
.portfolio-gallery a{position: relative; display:block}

/* metro gallery */
.metro-gallery.hover-option10 .grid-item figure .portfolio-icon {margin-bottom: 0;}

/* justify-gallery */
.justify-hover-icon {position: absolute;height: 100%; width: 100%; background: rgba(0,0,0,0.6); top: 0; left: 0; text-align: center; opacity: 0;}
.justify-hover-icon .ti-zoom-in {font-size: 24px; position: absolute; top: 50%; left: 0; right: 0; -webkit-transform: translateY(100%);-ms-transform: translateY(100%);transform: translateY(100%);}
.justify-gallery-content:hover .justify-hover-icon .ti-zoom-in {-webkit-transform: translateY(-50%);-ms-transform: translateY(-50%);transform: translateY(-50%);}
.justify-gallery-content:hover .justify-hover-icon {opacity: 1}

.mfp-arrow-right:after, .mfp-arrow-left:after {display: none}
.mfp-arrow-right:before, .mfp-arrow-left:before {width: initial; height: initial; position: initial; left: initial; top: initial; border: initial;}
.mfp-arrow-right:before {border-left: none; content: "\e628"; font-size: 2em; font-family: 'themify'; color: #fff;}
.mfp-arrow-left:before {border-right: none; content: "\e629"; font-size: 2em; font-family: 'themify'; color: #fff;}
.mfp-bottom-bar {margin-top: -24px; font-size: 12px}
.mfp-close {font-size: 36px; font-family: $font-family-base; font-weight: 500}
.mfp-arrow-left {left: 10px;}
.mfp-arrow-right {right: 10px;}
.left-nav-sidebar .mfp-arrow-left {left: 90px;}
.left-nav-sidebar .mfp-container {padding-left: 90px;}

@-webkit-keyframes fadeIn {0% {opacity: 0;} 100% {opacity: 1;}}
@-moz-keyframes fadeIn {0% {opacity: 0;} 100% {opacity: 1;}}
@-o-keyframes fadeIn {0% {opacity: 0;} 100% {opacity: 1;}}
@keyframes fadeIn {0% {opacity: 0;} 100% {opacity: 1;}}

.mfp-figure {-webkit-animation-name: fadeIn; -moz-animation-name: fadeIn; -o-animation-name: fadeIn; -ms-animation-name: fadeIn; animation-name: fadeIn; -webkit-animation-duration: .4s; -moz-animation-duration: .4s; -ms-animation-duration: .4s; -o-animation-duration: .4s; animation-duration: .4s; -webkit-animation-fill-mode: both; -moz-animation-fill-mode: both; -ms-animation-fill-mode: both; -o-animation-fill-mode: both; animation-fill-mode: both; webkit-backface-visibility: hidden; -moz-backface-visibility:hidden;  -ms-backface-visibility:hidden;}

/* start state */
.my-mfp-zoom-in .zoom-anim-dialog {opacity: 0; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; -webkit-transform: scale(0.8);  -moz-transform: scale(0.8); -ms-transform: scale(0.8);  -o-transform: scale(0.8); transform: scale(0.8);}

/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {opacity: 1; -webkit-transform: scale(1); -moz-transform: scale(1); -ms-transform: scale(1); -o-transform: scale(1); transform: scale(1);}

/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {-webkit-transform: scale(0.8); -moz-transform: scale(0.8); -ms-transform: scale(0.8); -o-transform: scale(0.8); transform: scale(0.8); opacity: 0;}

/* dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {opacity: 0; -webkit-transition: opacity 0.3s ease-out; -moz-transition: opacity 0.3s ease-out; -o-transition: opacity 0.3s ease-out; -ms-transition: opacity 0.3s ease-out; transition: opacity 0.3s ease-out;}

/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {opacity: 0;}

/* fade move animation for second dialog */
.my-mfp-slide-bottom .zoom-anim-dialog {opacity: 0; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; -webkit-transform: translateY(-20px) perspective(600px) rotateX(10deg); -moz-transform: translateY(-20px) perspective(600px) rotateX(10deg); -ms-transform: translateY(-20px) perspective(600px) rotateX(10deg); -o-transform: translateY(-20px) perspective(600px) rotateX(10deg); -ms-transform: translateY(-20px) perspective(600px) rotateX(10deg); transform: translateY(-20px) perspective(600px) rotateX(10deg);}

/* animate in */
.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {opacity: 1; -webkit-transform: translateY(0) perspective(600px) rotateX(0); -moz-transform: translateY(0) perspective(600px) rotateX(0); -ms-transform: translateY(0) perspective(600px) rotateX(0); -o-transform: translateY(0) perspective(600px) rotateX(0); transform: translateY(0) perspective(600px) rotateX(0);}

/* animate out */
.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {opacity: 0; -webkit-transform: translateY(-10px) perspective(600px) rotateX(10deg); -moz-transform: translateY(-10px) perspective(600px) rotateX(10deg); -ms-transform: translateY(-10px) perspective(600px) rotateX(10deg); -o-transform: translateY(-10px) perspective(600px) rotateX(10deg); transform: translateY(-10px) perspective(600px) rotateX(10deg);}

/* dark overlay, start state */
.my-mfp-slide-bottom.mfp-bg {opacity: 0;-webkit-transition: opacity 0.3s ease-out; -moz-transition: opacity 0.3s ease-out; -o-transition: opacity 0.3s ease-out; -ms-transition: opacity 0.3s ease-out; transition: opacity 0.3s ease-out;}

/* animate out */
.my-mfp-slide-bottom.mfp-removing.mfp-bg {opacity: 0;}

/* simple fade transition */
.mfp-fade.mfp-bg {opacity: 0; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -ms-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out;}
.mfp-fade.mfp-bg.mfp-ready {opacity: 0.97;}
.mfp-fade.mfp-bg.mfp-removing {opacity: 0;}
.mfp-fade.mfp-wrap .mfp-content {opacity: 0; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -ms-transition: all 0.3s ease-out; transition: all 0.3s ease-out;}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {opacity: 1;}
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {opacity: 0;}

/* grid */
.show-grid [class^="col-"] {background-color: #f5f5f5; border: 1px solid rgba(0, 0, 0, 0.2); margin-bottom: 15px; padding: 10px 0; text-align: center;}

/* message box */
.alert-dismissable .close, .alert-dismissible .close {top: 0}
.alert-dismissible .btn-close {height: 100%; background-size: 10px; padding-top: 0; padding-bottom: 0; outline: none;}
.alert-dismissible .btn-close:focus {box-shadow: none;}