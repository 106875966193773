/* ===================================
	24. Elements
====================================== */

/* double quotes */ 
.special-char-small {font-size: 40px; line-height: 60px;}
.special-char-medium{font-size: 80px; line-height: 120px;}
.special-char-large {font-size: 140px; line-height: 120px;}
.special-char-extra-large {font-size: 200px; line-height: 150px;}

/* search box */
.search-box .add-on .input-group-append > .btn, .search-box .add-on .input-group-btn > .btn {left:0px; border-color:$very-light-gray; box-shadow: none; -moz-box-shadow: none; -webkit-box-shadow: none; -o-box-shadow: none; -ms-box-shadow: none; margin-right: 0; margin-left: -1px; text-transform: uppercase; border-width: 1px; border-style: solid;padding: 5px 9px; height: 31px; border-top-right-radius: 4px; border-bottom-right-radius: 4px; border-left: 0;}
.search-box .add-on .form-control:focus, .search-box .add-on .input-group-btn > .btn:focus, .search-box .add-on .input-group-btn > .btn:hover {box-shadow:none; -webkit-box-shadow:none; -moz-box-shadow:none; -ms-box-shadow:none; -o-box-shadow:none; border-color:$very-light-gray; background: #f7f7f7}
.search-box .add-on i{color: $medium-gray; font-size: 11px; top: -5px; left: -2px}
.sidebar-nav-style-1 .search-box .add-on i{top: -4px;}
.search-box input[type="text"],.search-box input[type="search"]{border-color:$very-light-gray; box-shadow: none; -moz-box-shadow: none; -webkit-box-shadow: none; -o-box-shadow: none; -ms-box-shadow: none; font-size: 12px; font-weight: normal; color: $extra-dark-gray; height: 31px;}
.search-box .add-on input[type="text"]{padding: 5px 8px}
.search-box input[type="text"]::-webkit-input-placeholder {color: $medium-gray; font-size: 10px; text-transform: uppercase; font-weight: 400; letter-spacing: 0.015em;}
.search-box input[type="text"]::-moz-placeholder {color: $medium-gray; font-size: 10px; text-transform: uppercase; font-weight: 400; letter-spacing: 0.015em}
.search-box input[type="text"]::-ms-input-placeholder {color: $medium-gray; font-size: 10px; text-transform: uppercase; font-weight: 400; letter-spacing: 0.015em}
.search-box input[type="text"]::-o-placeholder {color: $medium-gray; font-size: 10px; text-transform: uppercase; font-weight: 400; letter-spacing: 0.015em;}

/* search box style 2 */
.search-box2 .add-on .input-group-append > .btn {border-left-width:0; left:0px; border-color:#4f4f4f; background-color: #4f4f4f; box-shadow: none; -moz-box-shadow: none; -webkit-box-shadow: none; -o-box-shadow: none; -ms-box-shadow: none; margin-right: 0;margin-left: -1px; text-transform: uppercase; border-width: 1px; border-style: solid; padding: 5px 8px 5px 10px; height: 45px; width: 45px;}
.search-box2 .add-on .input-group-append {width: 45px;}
.search-box2 .add-on .input-group-append > .btn:focus, .search-box2 .add-on .input-group-append > .btn:hover {box-shadow:none; -webkit-box-shadow:none; -moz-box-shadow:none; -ms-box-shadow:none; -o-box-shadow:none; border-color:#4f4f4f; background-color: #4f4f4f;}
.search-box2 .add-on i{color: $light; font-size: 17px}
.search-box2 input[type="email"],.search-box2 input[type="search"]{border-color:#474747; background-color: transparent; box-shadow: none; -moz-box-shadow: none; -webkit-box-shadow: none; -o-box-shadow: none; -ms-box-shadow: none;font-size: 14px; font-weight: normal; color: #636363; height: 45px}
.search-box2 .add-on input[type="email"]{padding: 10px 14px}
.search-box2 input[type="email"]::-webkit-input-placeholder {color: #636363; font-size: 14px; font-weight: 400; letter-spacing: 0.015em; background-color: transparent;}
.search-box2 input[type="email"]::-moz-placeholder {color: #636363; font-size: 14px; font-weight: 400; letter-spacing: 0.015em; background-color: transparent;}
.search-box2 input[type="email"]::-ms-input-placeholder {color: #636363; font-size: 14px; font-weight: 400; letter-spacing: 0.015em; background-color: transparent;}
.search-box2 input[type="email"]::-o-placeholder {color: #636363; font-size: 14px; font-weight: 400; letter-spacing: 0.015em; background-color: transparent;}

/* search form */
.search-cart-header {padding-top: 14px; float: right; letter-spacing: 1px}
.header-search-form {color: $extra-dark-gray}
.search-form .search-input {border: none; border-radius: 0; border-bottom: 2px solid rgba(255,255,255,0.5); font-size: 30px; font-weight: 100; padding: 20px 38px 20px 2px; text-transform: capitalize; background: transparent; line-height: 40px; color: $light;}
.search-form .search-input:focus{outline: none}
.search-form .search-input.required-error {border: none !important; border-bottom: 2px solid #ff0000 !important;}
.search-input ::-moz-placeholder, .search-input [placeholder] {color: #ff0000;}
.search-form .search-button {font-size:21px; color:$light; top:31px; right: 0; outline: none;}
#search-header {height:100%; width: 45%; margin:0 auto;}
.close-search {color: #373737; position: absolute; right: 0; top: 23px; z-index: 998; cursor: pointer;}
.close-search:hover {color: #9a9a9a;}
.search-button {cursor: pointer;  z-index: 0; margin-right: 0; border: none; background: none;}
aside .search-button {border: 2px solid transparent;}

/* list style 1 */
.list-style-none li {list-style: none;}
.list-style-1 li {padding: 5px 0}

/* list style 2 */
.list-style-2 {list-style: none; margin: 0; padding: 0;}
.list-style-2 li {position: relative; padding: 0 0 0 12px; margin: 0 0 9px 0;}
.list-style-2 li:before {content: ""; width: 5px; height: 1px; background-color: var(--base-color); position: absolute; top: 12px; left: 0;}

/* list style 3 */
.list-style-3 {list-style: none; margin: 0; padding: 0;}
.list-style-3 li {position: relative; padding: 0 0 0 22px; margin: 0 0 15px 0;}
.list-style-3 li:before {content: ""; width: 10px; height: 1px; background-color: var(--base-color); position: absolute; top: 11px; left: 0;}

/* list style 4 */
.list-style-4 {list-style: none; margin: 0; padding: 0;}
.list-style-4 li {position: relative; padding: 0 0 10px 22px; margin: 0 0 10px 0; border-bottom: 1px solid rgba(0, 0, 0, 0.1);}
.list-style-4 li:before {content: "\f00c"; position: absolute; top: 1px; left: 0; font-family: Font Awesome\ 5 Free; font-weight: 900;}
.list-style-4 li:last-child {border-bottom: 0;}
.list-style-4.list-style-color li {border-color: rgba(255,255,255,.15);}
.list-style-4.list-style-color li:before {color: var(--base-color);}

/* list style 5 */
.list-style-5 {list-style: none; margin: 0; padding: 0;}
.list-style-5 li {position: relative; padding: 0 0 10px 22px; margin: 0 0 10px 0; border-bottom: 1px solid rgba(0, 0, 0, 0.1);}
.list-style-5 li:before {content: "\f192"; position: absolute; top: 1px; left: 0; font-family: Font Awesome\ 5 Free; font-weight: 400; color: #c0c0c0}
.list-style-5 li:last-child {border-bottom: 0;}
.list-style-5.list-style-color li {border-color: rgba(255,255,255,.15);}
.list-style-5.list-style-color li:before {color: var(--base-color)}

/* list style 6 */
.list-style-6 {list-style: none; margin: 0; padding: 0;}
.list-style-6 li {position: relative; padding: 0 15px 8px 0; margin: 0 0 8px 0; border-bottom: 1px solid rgba(0, 0, 0, 0.1);}
.list-style-6 li span {position: absolute; top: 3px; right: 0;}
.list-style-6 li:last-child {border-bottom: none}
.list-style-6 li:before {content: "";}

/* list style 7 */
.list-style-7 {list-style: none; margin: 0; padding: 0;}
.list-style-7 li {padding: 0 0 13px 0; margin: 0 0 10px 0;}
.list-style-7 li:last-child {margin: 0; padding: 0;}

/* list style 8 */
.list-style-8 {list-style: none; margin: 0; padding: 0;}
.list-style-8 li {position: relative; padding:15px 0; margin: 0 0 4px 0; border-bottom: 1px solid rgba(0, 0, 0, 0.1);}
.list-style-8 li span {margin-bottom: 2px}
.list-style-8 li p {margin-bottom: 0}
.list-style-8 li:last-child {border-bottom: none}
.list-style-8 li:before {content: "";}

/* list style 9 */
.list-style-9 {list-style: none; margin: 0; padding: 0;}
.list-style-9 li {position: relative; padding:15px 0; margin: 0 0 4px 0; border-bottom: 1px solid #373737;}
.list-style-9 li span {margin-bottom: 2px}
.list-style-9 li p {margin-bottom: 0}
.list-style-9 li:last-child {border-bottom: none}
.list-style-9 li:before {content: "";}

/* list style 10 */
.list-style-10 {list-style: none; margin: 0; padding: 0;}
.list-style-10 li {padding:15px 0; margin: 0; border-bottom: 1px solid rgba(0, 0, 0, 0.1);}
.list-style-10 li i {float: left; margin-top: 5px}
.list-style-10 li span {padding-left: 15px; display: table}
.list-style-10 li:last-child {border-bottom: none}

/* list style 11 */
.list-style-11 {list-style: none; margin: 0; padding: 0;}
.list-style-11 li {position: relative; padding: 0 0 8px 0; margin: 0 0 8px 0; border-bottom: 1px solid rgba(0, 0, 0, 0.1);}
.list-style-11 li span {position: absolute; top: 3px; right: 0;}
.list-style-11 li:last-child {border-bottom: none}
.list-style-11 li:before {content: "";}

/* list style 12 */
.list-style-12 {list-style: none; margin: 0; padding: 0;}
.list-style-12 li {position: relative; padding-left: 30px; margin-bottom: 12px;}
.list-style-12 li:before {content: "\f00c"; position: absolute; top: 1px; left: 0; font-family: Font Awesome\ 5 Free; font-weight: 900;}
.list-style-12.list-style-color li:before {color: var(--base-color);}

/* image hover style 1 */
.image-hover-style-1 {background: #222222}
.image-hover-style-1:hover img{opacity: 0.5; transform: scale(1.2, 1.2) rotate(-5deg); transition: all 0.3s ease 0s;}

/* image hover style 3 */
.image-hover-style-3:hover .cover-background {filter: contrast(130%); -webkit-filter:contrast(130%); -moz-filter: contrast(130%); -ms-filter: contrast(130%); -o-filter: contrast(130%); filter: grayscale(1); -webkit-filter: grayscale(1); -moz-filter: grayscale(1); -ms-filter: grayscale(1); -o-filter: grayscale(1);}

/* info banner style 1 */
.banner-style1 figure {position: relative; overflow: hidden; transition-timing-function: ease-in-out; -moz-transition-timing-function: ease-in-out; -webkit-transition-timing-function: ease-in-out; -o-transition-timing-function: ease-in-out; transition-duration: .2s; -moz-transition-duration: .2s; -webkit-transition-duration: .2s; -o-transition-duration: .2s; -ms-transition-duration: .2s;}
.banner-style1 figure figcaption{position: absolute; left: 0; top: 50%; transform: translateY(-50%); -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%); height: 100%; width: 100%;}
.banner-style1 figure:hover .banner-image img, .banner-style1 figure:hover .banner-image {opacity:0.3; filter: contrast(130%); -webkit-filter:contrast(130%); -moz-filter: grayscale(1); -ms-filter: grayscale(1); -o-filter: grayscale(1); filter: grayscale(1); -webkit-filter: grayscale(1); -moz-filter: grayscale(1); -ms-filter: grayscale(1); -o-filter: grayscale(1);}
.banner-style1 figure figcaption p{opacity: 0; margin-bottom: 0; height: 0}
.banner-style1 figure:hover figcaption p {height: 100px; opacity: 1;}

/* info banner style 2 */
figure{margin: 0;}
.banner-style2 figure {position: relative; overflow: hidden}
.banner-style2 figure:hover .banner-image img{opacity:0.4;}
.banner-style2 figcaption {position: absolute; right: -50px; top: 40px; height: 100%; height: calc(100% - 80px); width: 50%; opacity: 0; visibility: hidden;}
.banner-style2 figure:hover figcaption {right: 40px; opacity: 1; visibility: visible}

/* info banner style 3 */
.banner-style3 figure {position: relative; cursor: pointer; overflow: hidden; transition-timing-function: ease-in-out; -moz-transition-timing-function: ease-in-out; -webkit-transition-timing-function: ease-in-out; -o-transition-timing-function: ease-in-out; -ms-transition-timing-function: ease-in-out; transition-duration: .2s; -moz-transition-duration: .2s; -webkit-transition-duration: .2s; -o-transition-duration: .2s; -ms-transition-duration: .2s;}
.banner-style3 figure figcaption{position: absolute; left: 0; top: 52%; transform: translateY(-50%); -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%); height: 100%; width: 100%; opacity: 0;}
.banner-style3 figure:hover figcaption {opacity: 1; top: 50%}
.banner-style3 figure:hover .banner-image img, .banner-style1 figure:hover .banner-image {opacity:0.3;}

/* pricing box */ 
.highlight .pricing-box {box-shadow: 0px 0px 40px rgba(200,200,200,0.3); transform: scale(1.15); -webkit-transform: scale(1.15); -moz-transform: scale(1.15); -ms-transform: scale(1.15); -o-transform: scale(1.15);}

.feature-box {overflow: hidden;position: relative;text-align: center; cursor: pointer}
.feature-box .content {padding: 35px 15px;}
.feature-box:before, .feature-box .content:before, .feature-box:after, .feature-box .content:after{display: inline-block; position:absolute; content:""; background-color:var(--base-color); z-index: 1}
.feature-box:before, .feature-box .content:before {right:0; top:0; transition-duration:0.5s; -webkit-transition-duration:0.5s; -moz-transition-duration:0.5s; -ms-transition-duration:0.5s; -o-transition-duration:0.5s;}
.feature-box:after, .feature-box .content:after {left:0; bottom:0; transition-duration:0.5s; -webkit-transition-duration:0.5s; -moz-transition-duration:0.5s; -ms-transition-duration:0.5s; -o-transition-duration:0.5s;}
.feature-box:hover:before, .feature-box:hover:after{transform:scaleY(1); transition-duration:0.5s; -webkit-transition-duration:0.5s; -moz-transition-duration:0.5s; -ms-transition-duration:0.5s; -o-transition-duration:0.5s;}
.feature-box:before {transform-origin: right top 0; -webkit-transform-origin: right top 0; -moz-transform-origin: right top 0; -ms-transform-origin: right top 0; -o-transform-origin: right top 0;}
.feature-box:after {transform-origin: left bottom 0; -webkit-transform-origin: left bottom 0; -moz-transform-origin: left bottom 0; -ms-transform-origin: left bottom 0; -o-transform-origin: left bottom 0;}
.feature-box .content:before {transform-origin: right top 0; -webkit-transform-origin: right top 0; -moz-transform-origin: right top 0; -ms-transform-origin: right top 0; -o-transform-origin: right top 0;}
.feature-box .content:after {transform-origin: top left 0; -webkit-transform-origin: top left 0; -moz-transform-origin: top left 0; -ms-transform-origin: top left 0; -o-transform-origin: top left 0;}
.feature-box:before, .feature-box:after{height:100%; width:3px; transform:scaleY(0);-webkit-transform:scaleY(0); -moz-transform:scaleY(0); -ms-transform:scaleY(0); -o-transform:scaleY(0); transition-duration:0.5s; -webkit-transition-duration:0.5s; -moz-transition-duration:0.5s; -ms-transition-duration:0.5s; -o-transition-duration:0.5s;}
.feature-box:hover .content:before, .feature-box:hover .content:after {transform:scaleX(1); -webkit-transform:scaleX(1); -moz-transform:scaleX(1); -ms-transform:scaleX(1); -o-transform:scaleX(1); transition-duration:0.5s; -webkit-transition-duration:0.5s; -moz-transition-duration:0.5s; -ms-transition-duration:0.5s; -o-transition-duration:0.5s;}
.feature-box .content:before, .feature-box .content:after {width:100%; height:3px; transform:scaleX(0); -webkit-transform:scaleX(0); -moz-transform:scaleX(0); -ms-transform:scaleX(0); -o-transform:scaleX(0); transition-duration:0.5s; -webkit-transition-duration:0.5s; -moz-transition-duration:0.5s; -ms-transition-duration:0.5s; -o-transition-duration:0.5s;}

/* feature box style 1 */
.feature-box-1 {display: table}
.feature-box-1 .char-value {display: table-cell; vertical-align: middle}

/* feature box style 4 */
.grid-item.feature-box-4 {overflow: hidden; position: relative;}
.grid-item.feature-box-4 figure img {width: 100%; cursor: auto;}
.grid-item.feature-box-4 figure figcaption {position: absolute; left: 0px; top: auto; z-index: 9; bottom: 0px; transform: translateY(0); -webkit-transform: translateY(0); -moz-transform: translateY(0); -ms-transform: translateY(0); -o-transform: translateY(0); transition: transform .35s ease-out; -webkit-transition: transform .35s ease-out; -moz-transition: transform .35s ease-out; -ms-transition: transform .35s ease-out; -o-transition: transform .35s ease-out; padding: 0 0 15px 60px; width: auto; height:auto; text-align: left; opacity: 1; cursor: default;}
.grid-item.feature-box-4 figure:hover img, .feature-box-4 figure:focus img {opacity: 1; -ms-transform: none; -webkit-transform: none; -moz-transform: none; -o-transform: none;  transform: none;}
.grid-item.feature-box-4 figure:hover figcaption {transform: translateY(-50px); -webkit-transform: translateY(-50px); -moz-transform: translateY(-50px); -ms-transform: translateY(-50px); -o-transform: translateY(-50px);}
.grid-item.feature-box-4 figure figcaption .btn {opacity: 0}
.grid-item.feature-box-4 figure:hover .btn {opacity: 1; display: inline-block}
.grid-item.feature-box-4 figure:hover img {transition-timing-function: ease-in-out; -webkit-transition-timing-function: ease-in-out; -moz-transition-timing-function: ease-in-out; -ms-transition-timing-function: ease-in-out; -o-transition-timing-function: ease-in-out; transform: scale(1.09,1.09); -webkit-transform: scale(1.09,1.09); -moz-transform: scale(1.09,1.09); -ms-transform: scale(1.09,1.09); -o-transform: scale(1.09,1.09);}

/* feature box style 5 */
.feature-box-5 {padding-left:75px}
.feature-box-5 i {position: absolute; top: 0; left: 0}

/* feature box style 6 */
.feature-box-6 {padding-left: 65px;}
.feature-box-6 i {position: absolute; top: 50%; left: 0; transform: translateY(-50%); -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%);}

/* feature box style 7 */
.feature-box.feature-box-7:before,.feature-box.feature-box-7:after {content: ''; display: table;}
.feature-box.feature-box-7:hover,.feature-box.feature-box-7:hover:before,.feature-box.feature-box-7:hover:after {border: none}
.feature-box.feature-box-7::before, .feature-box.feature-box-7::after, .feature-box.feature-box-7 .content::before, .feature-box.feature-box-7 .content::after {background: transparent;}
.feature-box.feature-box-7:after {clear: both;}
.feature-box.feature-box-7 .box {cursor: default; height: 100%; width: 100%; display: table; position: relative; -webkit-transition: all 0.4s cubic-bezier(.48,.83,.63,.91); -moz-transition: all 0.4s cubic-bezier(.48,.83,.63,.91); -mstransition: all 0.4s cubic-bezier(.48,.83,.63,.91); -o-transition: all 0.4s cubic-bezier(.48,.83,.63,.91); transition: all 0.4s cubic-bezier(.48,.83,.63,.91); border: 1px solid $very-light-gray; border-right: none; overflow: hidden}
.feature-box.feature-box-7 div:first-child .box {border-left: none}
.feature-box.feature-box-7 .box figure {-webkit-transition: all 0.4s cubic-bezier(.48,.83,.63,.91); -moz-transition:all 0.4s cubic-bezier(.48,.83,.63,.91); transition: all 0.4s cubic-bezier(.48,.83,.63,.91); -ms-transition: all 0.4s cubic-bezier(.48,.83,.63,.91); -o-transition: all 0.4s cubic-bezier(.48,.83,.63,.91); transition: all 0.4s cubic-bezier(.48,.83,.63,.91); padding: 8% 0;}
.feature-box.feature-box-7 .box .content {display:table-cell; height: 100%; vertical-align: middle; padding: 0; position: relative; top: 30px;}
.feature-box.feature-box-7 .box .content .details {opacity: 0;}
.feature-box.feature-box-7 .box:hover .content .details {opacity: 1;}
.feature-box.feature-box-7 .box:hover .content {top: 0; border-bottom-color: var(--base-color);}
.feature-box.feature-box-7 .box:hover i {color: var(--base-color)}
.container .feature-box.feature-box-7 .box .content {top: 43px;}
.container .feature-box.feature-box-7 .box:hover .content {top: 0;}

/* feature box style 8 */
.feature-box-8 .feature-box-overlay {bottom: 0; left: 0; right: 0; width: 100%; height: 2px; position: absolute; z-index: -2}
.feature-box-8:hover .feature-box-overlay {height: 100%; background: #2f2f2f}
.feature-box-8:hover div, .feature-box-8:hover p {color: $light;}
.feature-box-8:hover .icon-round-small{background: $light; color: var(--base-color) !important;}
.parallax-feature-box-bottom {position: absolute; bottom:-150%;}
.parallax-feature-box {overflow: visible}

/* feature box style 9 */
.feature-box-9 i {position: relative; transition: all 0.3s ease 0s; -webkit-transition: all 0.3s ease 0s; -moz-transition: all 0.3s ease 0s; -ms-transition: all 0.3s ease 0s; -o-transition: all 0.3s ease 0s; top:0}
.feature-box-9:hover i {top: -8px; color: $light}
.feature-box-9:hover p {color: var(--base-color);}
.feature-box-9 .text-bottom-line {margin-bottom: 15px}
.feature-box-9:hover .text-bottom-line {border-top:45px solid !important; margin-bottom:0}

/* feature box style 10 */
.feature-box-10 .number {display: flex; font-size: 30px; height: 90px; margin: 0 auto; text-align: center; vertical-align: middle; width: 90px; position: relative; align-items: center; justify-content: center;}
.feature-box-10:hover .number {background: var(--base-color); color: $light}
.feature-box-10 .number-center:after {width: 250%; position: absolute; content:""; height: 1px; background: #e2e2e2; top: 45px; z-index: -1; left:130px}
.feature-box-10 .number-center:before {width: 250%; position: absolute; content:""; height: 1px; background: #e2e2e2; top: 45px; z-index: -1; right:130px}

/* feature box style 11 */
.feature-box-11 .progress-line:after {content: ""; display: block; width: 12%; height: 1px; background: #d8d8d8; position: absolute; right: -6%; top: 65px; text-align: center;}
.feature-box-11 .process-bg {border-radius: 100%; height: 130px; width: 130px; line-height: 130px}

/* feature box style 12 */
.feature-box-12 .feature-icon-box {width: 90px; height: 90px; border-radius: 6px; text-align: center; vertical-align: middle; border: 1px solid $light-gray; display: table-cell; transform: rotateX(0deg); -webkit-transform: rotateX(0deg); -moz-transform: rotateX(0deg); -ms-transform: rotateX(0deg); -o-transform: rotateX(0deg); transition: ease-in-out 0.6s; -webkit-transition: ease-in-out 0.6s; -moz-transition: ease-in-out 0.6s; -ms-transition: ease-in-out 0.6s; -o-transition: ease-in-out 0.6s;}
.feature-box-12 .feature-content-box {display: table-cell; vertical-align: middle; transform: rotateY(0deg); -webkit-transform: rotateY(0deg); -moz-transform: rotateY(0deg); -ms-transform: rotateY(0deg); -o-transform: rotateY(0deg); transition: ease-in-out 0.6s; -webkit-transition: ease-in-out 0.6s; -moz-transition: ease-in-out 0.6s; -ms-transition: ease-in-out 0.6s; -o-transition: ease-in-out 0.6s; opacity: 1;}
.feature-box-12 .hover-content-box, .feature-box-12 .feature-content-box {backface-visibility: hidden; transform-style: preserve-3d; -webkit-transform-style: preserve-3d; -moz-transform-style: preserve-3d; -ms-transform-style: preserve-3d; -o-transform-style: preserve-3d;}
.feature-box-12 .hover-content-box {background-color: $extra-dark-gray; width: 80%; transition: ease-in-out 0.6s; -webkit-transition: ease-in-out 0.6s; -moz-transition: ease-in-out 0.6s; -ms-transition: ease-in-out 0.6s; -o-transition: ease-in-out 0.6s; transform: translateY(-50%) rotateX(180deg); -webkit-transform: translateY(-50%) rotateX(180deg); -moz-transform: translateY(-50%) rotateX(180deg); -ms-transform: translateY(-50%) rotateX(180deg); -o-transform: translateY(-50%) rotateX(180deg); position: absolute; top: 50%;left: 0; opacity: 0;}
.feature-box-12:hover .hover-content-box {transform: translateY(-50%) rotateX(0deg); -webkit-transform: translateY(-50%) rotateX(0deg); -moz-transform: translateY(-50%) rotateX(0deg); -ms-transform: translateY(-50%) rotateX(0deg); -o-transform: translateY(-50%) rotateX(0deg); opacity: 1}
.feature-box-12:hover .feature-icon-box, .feature-box-12:hover .feature-content-box {transform: rotateX(180deg); -webkit-transform: rotateX(180deg); -moz-transform: rotateX(180deg); -ms-transform: rotateX(180deg); -o-transform: rotateX(180deg); opacity: 0}

/* feature box style 13 */
.feature-box-13 {overflow: hidden; z-index: 1;}
.feature-box-13:before {background: var(--base-color); left: 0; height: 100%;  top: 0; position: absolute; width: 100%; z-index: -1; display: block; content: ""; transition-duration: 0.3s; -webkit-transition-duration: 0.3s; -moz-transition-duration: 0.3s; -ms-transition-duration: 0.3s; -o-transition-duration: 0.3s; transform:scaleY(0); -webkit-transform:scaleY(0); -moz-transform:scaleY(0); -ms-transform:scaleY(0); -o-transform:scaleY(0); opacity: 0}
.feature-box-13:hover:before {transform: scaleY(1); -webkit-transform: scaleY(1); -moz-transform: scaleY(1); -ms-transform: scaleY(1); -o-transform: scaleY(1); transition-duration: 0.3s; -webkit-transition-duration: 0.3s; -moz-transition-duration: 0.3s; -ms-transition-duration: 0.3s; -o-transition-duration: 0.3s; opacity: 1}
.feature-box-13:hover i {color: $extra-dark-gray}
.feature-box-13:hover p {color: $light}

/* feature box style 14 */
.feature-box-14 > div {border-right: 1px solid $very-light-gray; border-bottom: 1px solid $very-light-gray; border-top: 0;}
.feature-box-14 > div:last-child {border-right: 0;}

/* feature box style 15 */
.feature-box-15 .feature-box-content {position: relative; overflow: hidden;}
.feature-box-15 .feature-box-image {transform: rotateY(0deg); -webkit-transform: rotateY(0deg); -moz-transform: rotateY(0deg); -ms-transform: rotateY(0deg); -o-transform: rotateY(0deg);}
.feature-box-15 .hover-content, .feature-box-15 .feature-box-image {backface-visibility: hidden; transform-style: preserve-3d; transform: rotateY(0deg); transition: ease-in-out 0.4s; -webkit-transform-style: preserve-3d; -webkit-transform: rotateY(0deg); -webkit-transition: ease-in-out 0.4s; -moz-transform-style: preserve-3d; -moz-transform: rotateY(0deg); -moz-transition: ease-in-out 0.4s; -ms-transform-style: preserve-3d; -ms-transform: rotateY(0deg); -ms-transition: ease-in-out 0.4s; -o-transform-style: preserve-3d; -o-transform: rotateY(0deg); -o-transition: ease-in-out 0.4s;}
.feature-box-15 .hover-content {left: 0; position: absolute; top:0; height: 100%; transform: rotateX(180deg); -webkit-transform: rotateX(180deg); -moz-transform: rotateX(180deg); -ms-transform: rotateX(180deg); -o-transform: rotateX(180deg); width: 100%; opacity: 0;}
.feature-box-15 .feature-box-content:hover .feature-box-image {transform: rotateX(180deg);-webkit-transform: rotateX(180deg); -moz-transform: rotateX(180deg); -ms-transform: rotateX(180deg); -o-transform: rotateX(180deg); opacity: 0;}
.feature-box-15 .feature-box-content:hover .hover-content {transform: rotateX(0deg); -webkit-transform: rotateX(0deg); -moz-transform: rotateX(0deg); -ms-transform: rotateX(0deg); -o-transform: rotateX(0deg); opacity: 1;}

/* feature box style 16 */
.feature-box-16 .feature-box-content {position: absolute; bottom:0; width: 100%; transition: ease-in-out 0.4s; height: 60px;}
.feature-box-16 .feature-box-content p {opacity: 0; transition: ease-in-out 0.5s; -webkit-transition: ease-in-out 0.5s; -moz-transition: ease-in-out 0.5s; -ms-transition: ease-in-out 0.5s; -o-transition: ease-in-out 0.5s;}
.feature-box-16 {position: relative; overflow: hidden;}
.feature-box-16:hover .feature-box-content {height: 100%;}
.feature-box-16:hover .feature-box-content p {opacity: 1;}

/* feature box style 17 */
.feature-box.feature-box-17:before,.feature-box.feature-box-17:after {content: ''; display: table;}
.feature-box.feature-box-17:hover,.feature-box.feature-box-17:hover:before,.feature-box.feature-box-17:hover:after {border: none}
.feature-box.feature-box-17::before, .feature-box.feature-box-17::after, .feature-box.feature-box-17 .content::before, .feature-box.feature-box-17 .content::after {background: transparent;}
.feature-box.feature-box-17:after {clear: both;}
.feature-box.feature-box-17 .box {cursor: default; height: 100%; width: 100%; display: table; position: relative; -webkit-transition: all 0.4s cubic-bezier(.48,.83,.63,.91); -moz-transition: all 0.4s cubic-bezier(.48,.83,.63,.91); -ms-transition: all 0.4s cubic-bezier(.48,.83,.63,.91); transition: all 0.4s cubic-bezier(.48,.83,.63,.91); overflow: hidden}
.feature-box.feature-box-17 div:first-child .box {border-left: none}
.feature-box.feature-box-17 .box figure {-webkit-transition: all 0.4s cubic-bezier(.48,.83,.63,.91); -ms-transition: all 0.4s cubic-bezier(.48,.83,.63,.91); -moz-transition:all 0.4s cubic-bezier(.48,.83,.63,.91); -o-transition: all 0.4s cubic-bezier(.48,.83,.63,.91); transition: all 0.4s cubic-bezier(.48,.83,.63,.91); padding: 10% 0;}
.feature-box.feature-box-17 .box .content {display:table-cell; height: 100%; vertical-align: middle; padding: 0; position: relative; top: 30px;}
.feature-box.feature-box-17 .box .content .details {opacity: 0;}
.feature-box.feature-box-17 .box:hover .content .details {opacity: 1;}
.feature-box.feature-box-17 .box:hover .content {top: 0; border-bottom-color: var(--base-color);}
.feature-box.feature-box-17 .box:hover {background-color: var(--base-color);}
.feature-box.feature-box-17 .box:hover i, .feature-box.feature-box-17 .box:hover .text-white-2-hover {color: $light}

/* feature box style 18 */
.feature-box-18 .feature-box-overlay {top: 0; left: 0; right: 0; width: 100%; height: 100%; position: absolute; z-index: -1; opacity: 0; overflow: hidden; transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out;}
.feature-box-18:hover .feature-box-overlay {opacity: 1;}
.feature-box-18:hover i, .feature-box-18:hover div, .feature-box-18:hover p {color: $light;}
.feature-box-18:hover p {opacity: .6;}

/* info banner box style 2 */
.info-banner-box-2 {position: relative; overflow: hidden; height: 200px;}
.info-banner-box-2 .hover-box {position: absolute; left: 100%; top: 0; height: 100%; transition: ease-in-out 0.5s; -webkit-transition: ease-in-out 0.5s; -moz-transition: ease-in-out 0.5s; -ms-transition: ease-in-out 0.5s; -o-transition: ease-in-out 0.5s;}
.info-banner-box-2 .content-box {position: absolute; left: 0; top:0; height: 100%; transition: ease-in-out 0.5s; -webkit-transition: ease-in-out 0.5s; -moz-transition: ease-in-out 0.5s; -ms-transition: ease-in-out 0.5s; -o-transition: ease-in-out 0.5s;}
.info-banner-box-2:hover .hover-box {left: 0}
.info-banner-box-2:hover .content-box {left: -100%}

/* counter box style 1 */
.counter-feature-box-1 {height:199px;}
.counter-feature-box-1:hover {background:var(--base-color); border-color: var(--base-color);}

/* counter box style 3 */
.counter-box-3 .counter-box {display: inline-block; width: 140px; padding: 0; vertical-align: top; text-align: center; position: relative;}
.counter-box-3 .counter-box:after {position: absolute; right: 0; width: 1px; height: 36px; background-color: #eb214b; content: ""; top: 8px;}
.counter-box-3 .counter-box:last-child:after {width: 0;}
.counter-box-3 .counter-box .number {display: block; font-weight: 400; font-size: 32px; line-height: 30px;}
.counter-box-3 .counter-box span {font-weight: 400; font-size: 12px; line-height: 28px; text-transform: uppercase;}

/* counter box style 4 */
.counter-box-4 .counter:after {content: "+"; position: absolute; right: -5%; top: 35%; color: #b6b6b6; font-weight: 300;}
.counter-box-4:last-child .counter:after {display:none;}

/* counter box style 5 */
.counter-box-5 .counter-box {display: inline-block; width: 13%; padding: 0; vertical-align: top; text-align: center; position: relative;} 
.counter-box-5 .counter-box .number {display: block; font-weight: 400; font-size: 80px; line-height: 80px; letter-spacing: -3px}
.counter-box-5 .counter-box span {font-weight: 400; font-size: 12px; letter-spacing: 1px; opacity: 0.5; line-height: 28px; text-transform: uppercase;}

/* progress bar */
.progress {overflow: visible; height: 5px; position: relative; border-radius: 10px; width: 100%;}
.progress .progress-title {position: absolute; top: -22px;}
.progress .progress-bar {position: relative; overflow: visible; border-radius: 10px;}
.progress .progress-bar span {position: absolute; top: -30px; right: 0; opacity: 0}
.progress .progress-bar.appear span {opacity: 1;}
.progress-bar-percent {position: absolute; top: -12px; right: -39px;}

/* progress bar style 01 */
.progress-step-style-01.progress {width: 85%;}

/* progress bar style 02 */
.progress-step-style-02.progress .progress-title {position: absolute; top: 15px;}
.progress-step-style-02 .progress-bar-percent {position: absolute; top: 13px; right: 0;}

/* progress bar style 03 */
.progress-step-style-03.progress {height: 3px; width: 88%;}
.progress-step-style-03 .progress-bar {height: 3px;}
.progress-step-style-03 .progress-bar-percent {top: -9px;}
.progress-step-style-03.progress .progress-title {top: -24px;}

/* piechart style 1 */
.chart-percent .pie-chart-style-01 {text-align: center; position: relative; width: auto; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center;}
.pie-chart-style-01 .percent {position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); -webkit-transform: translate(-50%,-50%); -moz-transform: translate(-50%,-50%); -ms-transform: translate(-50%,-50%);}

/* piechart style 2 */
.chart-percent .pie-chart-style-02 {text-align: center; position: relative; width: auto; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center;}
.pie-chart-style-02 .percent {position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); -webkit-transform: translate(-50%,-50%); -moz-transform: translate(-50%,-50%); -ms-transform: translate(-50%,-50%);}

/* piechart style 3 */
.chart-percent .pie-chart-style-03 {text-align: center; position: relative; width: auto; display: inline-block;}
.pie-chart-style-03 .percent {display: inline-block; line-height: 68px; z-index: 2; background: $light; border-radius: 100%; width: 70px; height: 70px; color: $extra-dark-gray; right: -25px; top: 10px; position: absolute;}

/* bootstrap panel style 3.7 */
.panel {border: 1px solid transparent;}
.panel .panel-heading {display: block;}
.panel-group {margin-bottom: 20px;}
.panel-group .panel-heading+.panel-collapse>.list-group, .panel-group .panel-heading+.panel-collapse>.panel-body {border-top: 1px solid #ddd;}

/* team style 1 */
.team-style-1 figure .team-image {position: relative; overflow: hidden;}
.team-style-1 .team-overlay {display: block; position: absolute; overflow: hidden; transition: all 0.3s ease 0s; -webkit-transition: all 0.3s ease 0s; -moz-transition: all 0.3s ease 0s; -ms-transition: all 0.3s ease 0s; -o-transition: all 0.3s ease 0s;}
.team-style-1 .team-overlay {bottom: 0; left: 0; right: 0; width: 100%; height: 0;}
.team-style-1 figure:hover .team-overlay {height: 100%;}
.team-style-1 figure figcaption {width: 100%; position: relative; margin-top: 17px}
.team-style-1 figure .overlay-content {opacity: 0; position: absolute; height: 100%; width: 100%; top:0; transition: all 0.3s ease 0s; -webkit-transition: all 0.3s ease 0s; -moz-transition: all 0.3s ease 0s; -ms-transition: all 0.3s ease 0s; -o-transition: all 0.3s ease 0s; top:-15px}
.team-style-1 figure:hover .overlay-content {opacity: 1; z-index: 1111; top:0}
.team-style-1 figure figcaption .team-member-position {position: relative; top: 0; transition: all 0.2s; -webkit-transition: all 0.2s; -moz-transition: all 0.2s; -ms-transition: all 0.2s; -o-transition: all 0.2s;}

/* team style 2 */
.team-style-2 figure {position: relative}
.team-style-2 figure .team-image {position: relative;}
.team-style-2 .team-overlay {display: block; position: absolute; overflow: hidden; transition: all 0.3s ease 0s; bottom: 0; left: 0; right: 0; width: 100%; height: 100%; opacity: 0}
.team-style-2 figure:hover .team-overlay {bottom: 0; left: 0; right: 0; width: 100%; height: 100%; opacity: .9}
.team-style-2 figure .team-member-position {position: absolute; top: 100%; width: 100%; transition: all 0.2s; -webkit-transition: all 0.2s; -moz-transition: all 0.2s; -ms-transition: all 0.2s; -o-transition: all 0.2s;}
.team-style-2 figure:hover .team-member-position {top: 50%; transform: translateY(-50%); -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%); margin: 0;}
.team-style-2 figure:hover .team-member-position .text-medium-gray, .team-style-2 figure:hover .team-member-position .text-extra-dark-gray {color: $light}
.team-style-2 figure figcaption {min-height: 20px;}
.team-style-2 figure figcaption .overlay-content {opacity: 0; top:0; position: relative}
.team-style-2 figure:hover figcaption .overlay-content {opacity: 1; top:30px;}

/* accordion style */
.panel-title {font-size: 16px;}
.panel-title > i {font-size: 12px; position: absolute; right: 0; top: 50%; transform: translateY(-50%); -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%);}

/* accordion style1 */
.accordion-style1 .panel  {background: transparent; box-shadow: none; margin-top: 0 !important}
.accordion-style1.panel-group .panel-heading  {border: 0px; border-bottom: 1px solid #e4e4e4;}
.accordion-style1 .panel-heading {padding: 16px 0;}
.accordion-style1 .panel-title {font-size: 13px; padding-right: 30px; position: relative}
.accordion-style1.panel-group .panel-heading+.panel-collapse>.list-group, .accordion-style1.panel-group .panel-heading+.panel-collapse>.panel-body{border-top:0; padding: 25px 0}

/* accordion style 2 */
.accordion-style2 .panel {background: transparent; border: none; margin-bottom: 15px; box-shadow: none;}
.accordion-style2 .panel-title {background: transparent; border: none}
.accordion-style2 .panel .panel-heading {background: $light; border-radius:0; padding:18px 28px 19px; position: relative;}
.accordion-style2 .panel-body{padding: 35px 28px 25px; border-top: 1px solid #ddd;}
.accordion-style2 .panel-heading i {font-size: 25px; margin: 0; right: 28px;}
.accordion-style2 .panel-title {padding: 0;}
.accordion-style2 .tab-tag {font-size: 20px; padding: 0; margin-right: 7px; display: inline-block; vertical-align: top;}
.accordion-style2 a, .accordion-style2 a:hover, .accordion-style2 a:hover i {color: $dark;}
.accordion-style2 .panel-default:last-child {border-bottom: 1px solid rgba(0, 0, 0, 0); margin-bottom: 0;}
.accordion-style2 {margin-bottom: 0;}

/* accordion style 3 */
.accordion-style3 .panel {background-color:transparent; border: none; margin-bottom: 15px; box-shadow: none; border-radius: 0;}
.accordion-style3 .panel-title {background: transparent; border: none; font-size: 16px;}
.accordion-style3 .panel .panel-heading {background: $black; color: $light; border-radius:0; padding:18px 28px 19px; position: relative;}
.accordion-style3 .panel-body{padding: 35px 28px 25px; border-top:none}
.accordion-style3 .panel-title > i {font-size: 25px; margin: 0; right: 28px;}
.accordion-style3 .tab-tag {font-size: 20px; padding: 0; margin-right: 7px; display: inline-block; vertical-align: top;}
.accordion-style3 a, .accordion-style2 a:hover, .accordion-style3 a:hover i {color: $light;}
.accordion-style3 .panel-default:last-child {border-bottom: 1px solid rgba(0, 0, 0, 0); margin-bottom: 0;}
.accordion-style3 {margin-bottom: 0;}

/* toggles */
.toggles .panel  {background: transparent; box-shadow: none; border-radius: 0; border: 1px solid #ddd;}
.toggles .panel-title  {font-size: 13px; padding-right: 30px; position: relative;}
.toggles .panel-title > span.float-end {position: absolute; right: 0; top: 0;}
.toggles .panel-heading {padding: 18px; background-color: transparent;}
.toggles .panel-heading i {font-size: 12px; color: $dark-gray}
.toggles .panel + .panel {margin-top: -1px;}
.toggles .panel-body {padding: 25px}

/* tab */
.nav-tabs>li>a.active, .nav-tabs>li>a.active:focus, .nav-tabs>li>a.active:hover {border: 0; border-bottom: 1px solid;}
.nav-tabs a.nav-link {transition: all .3s ease-in-out; -moz-transition: all .3s ease-in-out; -webkit-transition: all .3s ease-in-out; -ms-transition: all .3s ease-in-out; -o-transition: all .3s ease-in-out;}

/* tab style 1 */
.tab-style1 .nav-tabs{border: none; width: 100%;}
.tab-style1 .nav-tabs li{display: inline-block; float: none; width:25%;}
.tab-style1 .nav-tabs li a{margin: 0;}
.tab-style1 .nav-tabs li:hover{background: transparent;}
.tab-style1 .nav-tabs > li > a, .tab-style1 .nav-tabs > li > a:focus, .tab-style1 .nav-tabs > li > a:hover{border: none; background-color: transparent;}
.tab-style1 .nav-tabs li:hover i, .tab-style1 .nav-tabs li .active i{color: var(--base-color);}
.tab-style1 .nav-tabs li:hover span, .tab-style1 .nav-tabs li .active span{color: $extra-dark-gray;}

/* tab style 2 */
.tab-style2 .tab-content{padding:55px 0 0; border-top: 1px solid #e5e5e5;}
.tab-style2 .nav-tabs{border:0;}
.tab-style2 .nav-tabs li{border:1px solid #e5e5e5; border-bottom: none; border-radius: 0; margin-bottom: 0; display:inline-block; float:none; margin-right: 5px}
.tab-style2 .nav-tabs li a {background-color:#f7f7f7; border:none; color:#575757; line-height: 44px; margin:0 !important; padding: 0 25px; border-radius: 0;}
.tab-style2 .nav-tabs-light li:first-child {border-left: 1px solid #e5e5e5; margin-left: 15px;}
.tab-style2 .nav-tabs > li > a.active {border-color:transparent; border: none; border-radius:0; background:$light; top:1px; position: relative;}

/* tab style 3 */
.tab-style3 .nav-tabs {border-bottom: 1px solid transparent}
.tab-style3 .nav-tabs li {float: none; display: inline-block; vertical-align: middle;}
.tab-style3 .nav-tabs li {float: none; display: inline-block; vertical-align: middle; margin: 0 23px; padding: 3px 0}
.tab-style3 .nav-tabs li a.nav-link.active {color: $extra-dark-gray}
.tab-style3 .nav-tabs>li>a.nav-link, .tab-style3 .nav-tabs>li>a.nav-link:focus, .tab-style3 .nav-tabs>li>a.nav-link.active:hover, .tab-style3 .nav-tabs>li>a.nav-link:hover {background-color: transparent; border: none; padding: 0;}
.tab-style3 .nav-tabs li a.nav-link.active, .tab-style3 .nav-tabs>li>a.nav-link.active:hover {border-bottom: 1px solid $extra-dark-gray;}
.tab-style3 .nav-link:hover {color: var(--base-color);}

/* tab style 4 */
.tab-style4 .tab-content{padding:0 0 0 55px; border-left: 1px solid #e5e5e5;}
.tab-style4 .nav-tabs{border:0;}
.tab-style4 .nav-tabs li{border:1px solid #e5e5e5; border-right: none; border-radius: 0; margin-bottom: 0; display:block; float:left; width: 100%; left: 1px;}
.tab-style4 .nav-tabs li a {background-color:#f7f7f7; border:none; color:#575757; line-height: 44px; margin:0 !important; padding: 0 25px; border-radius: 0; top:1px; position: relative;}
.tab-style4 .nav-tabs li:last-child a {top: 0}
.tab-style4 .nav-tabs-light li:first-child {border-left: 1px solid #e5e5e5; margin-left: 15px;}
.tab-style4 .nav-tabs > li > a.active {border-color:transparent; border: none; border-radius:0; background:$light; left: 1px;}

/* tab style 5 */
.tab-style5 .nav-tabs {border: none;}
.tab-style5 .nav-tabs li {width: 100%; font-size: 26px; line-height: 60px; margin-bottom: 0;}
.tab-style5 .nav-tabs li a {border: none !important; border-bottom: 2px solid transparent !important; color: $medium-gray; opacity: 0.6; display: inline-block; background-color: transparent !important; padding: 0; border-radius: 0; color: $medium-gray;}
.tab-style5 .nav-tabs li a:hover {background-color: transparent; opacity: 1; color: var(--base-color);}
.tab-style5 .nav-tabs .nav-link.active {color: var(--base-color) !important; opacity: 1; border-bottom: 2px solid var(--base-color) !important; padding-left: 45px}
.tab-style5 .nav-tabs li.active a, .tab-style5 .nav-tabs li.active a:hover, .tab-style5 .nav-tabs li.active a:focus {border: none; cursor: pointer; background-color: transparent;}
.tab-style5 .tab-box {position: absolute; left:23%; top:50%; -webkit-transform: translate(-50%, -50%);-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%); z-index: 1; width:500px;}

/* testimonial style 2 */
.testimonial-style2 .profile-box {margin-top: -50px}

/* testimonial style 3 */
.testimonial-style3 .arrow-bottom:after {border-top-color: $light-gray; left: 15%;  border-width: 10px; margin-left: -10px;}
.testimonial-style3 .image-box img {width: 100%;}

/* testimonial-box */
.testimonial-box .image-box {display: table-cell; vertical-align: middle}
.testimonial-box .name-box {display: table-cell; vertical-align: middle}