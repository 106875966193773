/* ----------------------------------

Version: 4
Elements ajoutés, ou surcharges aux fichiers SCSS composant la version du thème, qui n'ont pas vocation à évoluer.

------------------------------------- */

.wow:not(.grid-item) {
	visibility: hidden;
}

// Typography
[class^="icon-"]:before, [class*=" icon-"]:before {
	font-family: inherit;
}	

.fontello {
	
	&[class^="icon-"]:before {
	  font-family: "fontello" !important;
	  
	}
	
	&[class*=" icon-"]:before {
		font-family: "fontello" !important;
		margin: 0;
	}
	
}

.text-primary-2, .btn.text-primary-2 {color:$primary}
.text-secondary-2, .btn.text-secondary-2 {color:$secondary}
.text-third-2, .btn.text-third-2 {color:$third}
.text-fourth-2, .btn.text-fourth-2 {color:$fourth}
.text-dark-2, .btn.text-dark-2 {color:$dark}

.title-medium {font-size: 30px; line-height:40px}

footer {
	a, a:active, a:focus {color: $footer-links; text-decoration: none;}
	a:hover, a:active{color: $footer-links-hover; text-decoration: none;} 
}

// Transitions
.vc_media_grid *, .skillbar-bar-main *, .portfolio-grid * { transition-timing-function: none; -moz-transition-timing-function: none; -webkit-transition-timing-function: none; -o-transition-timing-function: none; -ms-transition-timing-function: none; transition-duration: 0s; -moz-transition-duration: 0s; -webkit-transition-duration: 0s; -o-transition-duration: 0s; -ms-transition-duration: 0s; }

// Boutons
.btn.btn-primary {background: $primary; border-color: $primary; color: $light;}
.btn.btn-primary:hover, .btn.btn-primary:focus {background: transparent !important; border-color: $primary !important; color: $primary !important;}

.btn.btn-secondary {background: $secondary; border-color: $secondary; color: $black;}
.btn.btn-secondary:hover, .btn.btn-secondary:focus {background: $light; border-color: $secondary !important; color: $black !important;}

.btn.btn-dark {background: $dark; border-color: $dark; color: $light;}
.btn.btn-dark:hover, .btn.btn-dark:focus {background: $light; border-color: $dark !important; color: $dark !important;}

.btn.btn-dark-secondary {background: $dark; border-color: $dark; color: $secondary;}
.btn.btn-dark-secondary:hover, .btn.btn-dark-secondary:focus {background: $light; border-color: $dark !important; color: $dark !important;}


// backgrounds
.bg-primary, .background-primary {background-color:$primary !important;}
.bg-secondary, .background-secondary {background-color:$secondary !important;}
.bg-third, .background-third {background-color:$third !important;}
.bg-fourth, .background-fourth {background-color:$fourth !important;}
.bg-dark, .background-dark {background-color:$dark !important;}

.bg-primary-opacity, .background-primary-opacity {background-color:$primary-opacity !important;}

.contain-background {
	position: relative !important; background-size: contain !important; overflow: hidden !important; background-position: center !important; background-repeat: no-repeat !important;
}


// Paddings
.minimal-paddings {padding: calc(#{$standard-padding} /2) 0; overflow: hidden;}
.standard-paddings {padding: $standard-padding 0; overflow: hidden;}
.standard-padding-top {padding-top: $standard-padding; padding-bottom: 0; overflow: hidden;}
.standard-padding-half-top {padding-top: calc(#{$standard-padding} /2); padding-bottom: 0; overflow: hidden;}
.standard-padding-bottom {padding-top: 0; padding-bottom: $standard-padding; overflow: hidden;}
.standard-padding-half-bottom {padding-top: 0; padding-bottom: calc(#{$standard-padding} /2); overflow: hidden;}
.standard-padding-top-half-bottom {padding-top: $standard-padding; padding-bottom: calc(#{$standard-padding} /2); overflow: hidden;}
.standard-padding-bottom-half-top {padding-top: calc(#{$standard-padding} /2); padding-bottom: $standard-padding; overflow: hidden;}

.h-50px {
	height: 50px;	
}

.top-15px {
	top: 15px;
}


.arrow-right{
	position: relative;
	z-index: 10;
	&.bg-dark:after {
		color: $dark;
	}
	
	&.bg-secondary:after {
		color: $secondary;
	}
}

.arrow-right:after{
	right: 4px; 
	top: 50%; 
	margin-top: -13px;
	height: 0; 
	width: 0; 
	position: absolute; 
	pointer-events: none; 
	font-family: "Font Awesome 5 Free"; font-weight: 700;
	font-size: 60px;
	content: '\f0da';
	z-index: 9;	
}

footer {
	
	.footer-logo {
		max-height: 65px;
		width: auto;
	}
	
}

.two-column {
	column-count: 2;
	-webkit-column-break-inside: avoid;
	page-break-inside: avoid;
	break-inside: avoid;
	
	li {
		-webkit-margin-before: 0;
		-webkit-margin-after: 0;
		display: inline;
		
		a {
			display: block;
			margin-bottom: 15px;
		}
	}
}

form {
	
	input.input-bg {
		background: $light;
		border-radius: 15px;
		border: 0px solid rgba(0, 0, 0, 0.1);
	}

	
	textarea.input-bg {
		background-color: $light;
		border-radius: 15px;
		border: 0 solid rgba(0, 0, 0, 0.1);
		padding: 10px 20px;
		margin-bottom: 15px;
		min-height: 130px;
	}
	
	.rgpd {
		display: flex;
		flex-direction: row-reverse;
		justify-content: start;
		
		label {
			@extend .text-small;
			font-weight: 300;
			text-align: left;
		}
		input[type="checkbox"] {
			position:relative;
			width:40px;
			flex: 0 0 40px;
			height:20px;
			visibility:hidden;
			//margin-right: 20px;
			margin-bottom: 0;
			display: inline-block !important;
			margin-right: 15px;
			
			-moz-appearance:initial; // Hack for Firefox Browsers
		  
			&:before {
				visibility:visible;
				position:absolute;
				display:block;
				width: 100%;
				height: 20px;
				top:0;
				background:$light-gray;
				content: '';
				border-radius:10px;
				border: solid 1px #ddd;
				transition: all .5s;
			}
			  
			&:after {
				visibility:visible;
				top:0;
				display:block;
				content:'';
				width:20px;
				height:20px;
				background:$light;
				position:absolute;
				border-radius: 50%;
				border: solid 1px #ddd;
				transition: all .5s;
				left:0;
			}
			  
			&:checked:after {
				left: 100%;
				margin-left:-20px;
			}
			  
			&:checked:before {
				background:$secondary;
			}
		  
		}
	}

}

