/* ===================================
	07. Border
====================================== */
.border-all {border: 1px solid $very-light-gray}

/* boder width */
.border-width-1 {border-width:1px;}
.border-width-2 {border-width:2px;}
.border-width-3 {border-width:3px;}
.border-width-4 {border-width:4px;}
.border-width-5 {border-width:5px;}
.border-width-6 {border-width:6px;}
.border-width-7 {border-width:7px;}
.border-width-8 {border-width:8px;}
.border-width-9 {border-width:9px;}
.border-width-10 {border-width:10px;}
.border-width-20 {border-width:20px;}

/* boder align */
.border-top {border-top: 1px solid;}
.border-bottom {border-bottom: 1px solid;}
.border-left {border-left: 1px solid;}
.border-right {border-right: 1px solid;}
.border-lr {border-left: 1px solid; border-right: 1px solid;}
.border-tb {border-top: 1px solid; border-bottom: 1px solid;}

/* border color */
.border-color-white {border-color:$light !important;}
.border-color-black {border-color:$black !important;}
.border-color-extra-dark-gray {border-color:$extra-dark-gray !important;}
.border-color-medium-dark-gray {border-color:$medium-gray !important;}
.border-color-dark-gray {border-color:$dark-gray !important;}
.border-color-extra-medium-gray {border-color:$extra-medium-gray !important;}
.border-color-medium-gray {border-color:$medium-gray !important;}
.border-color-extra-light-gray {border-color:$very-light-gray !important;}
.border-color-light-gray {border-color:$light-gray !important;}
.border-color-light-pink {border-color:$primary !important;}
.border-color-deep-pink {border-color:$primary !important;}
.border-color-transparent-pink {border-color: $primary-opacity !important;}

.required-error {border: 1px solid #ee2323 !important}
.input-border-bottom.required-error {border-width: 0 !important; border-bottom-width: 1px !important; border-bottom-color: #ee2323 !important}
.g-recaptcha.required-error iframe {border: 1px solid #ee2323;}
.g-recaptcha.required-error {border: 0 !important}

/* boder style */
.border-dotted {border-style: dotted !important;}
.border-dashed {border-style: dashed !important;}
.border-solid {border-style: solid !important;}
.border-double {border-style: double !important;}
.border-groove {border-style: groove !important;}
.border-ridge {border-style: ridge !important;}
.border-inset {border-style: inset !important;}
.border-outset {border-style: outset !important;}
.border-none {border-style: none !important;}
.border-hidden {border-style: hidden !important;}
.border-transperent {border-color: transparent !important;}
.no-border-top {border-top:0 !important}
.no-border-bottom {border-bottom:0 !important}
.no-border-right {border-right:0 !important}
.no-border-left {border-left:0 !important}

/* border for overline icon box */
.overline-icon-box{border-top-width: 4px;}

/* border color for overline icon box */
.overline-icon-box.overline-white{border-top-color: $light !important;}
.overline-icon-box.overline-black{border-top-color: $black !important;}
.overline-icon-box.overline-extra-dark-gray{border-top-color: $extra-dark-gray !important;}
.overline-icon-box.overline-medium-dark-gray{border-top-color: $medium-gray !important;}
.overline-icon-box.overline-dark-gray{border-top-color: $dark-gray !important;}
.overline-icon-box.overline-extra-light-gray{border-top-color: $extra-light-gray !important;}
.overline-icon-box.overline-medium-gray{border-top-color: $medium-gray !important;}
.overline-icon-box.overline-light-gray{border-top-color: $light-gray !important;}
.overline-icon-box.overline-light-pink{border-top-color: $primary !important;}
.overline-icon-box.overline-deep-pink{border-top-color: $primary !important;}
.overline-icon-box.overline-transparent-pink{border-top-color: $primary-opacity !important;}

/* transparent border */
.border-black-light {border-color: rgba(0,0,0,.1) !important;}
.border-white-light {border-color: rgba(255,255,255,.1) !important;}

/* border radius */
.border-radius-1 {border-radius:1px}
.border-radius-2 {border-radius:2px}
.border-radius-3 {border-radius:3px}
.border-radius-4 {border-radius:4px}
.border-radius-5 {border-radius:5px}
.border-radius-6 {border-radius:6px}
.border-radius-7 {border-radius:7px}
.border-radius-8 {border-radius:8px}
.border-radius-9 {border-radius:9px}
.border-radius-10 {border-radius:10px}
.border-radius-50 {border-radius:50%}
.border-radius-100 {border-radius:100%}
.border-radius-none {border-radius:0}