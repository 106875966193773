/* ===================================
	06. Button
====================================== */

.btn {display:inline-block; border:1px solid transparent; color: inherit; letter-spacing: .5px; line-height: inherit; border-radius: 0; text-transform: uppercase; width: auto; font-family: $font-family-base; font-weight: 600; transition-duration: 0.3s; transition-timing-function: ease-in-out; white-space: nowrap;}
.btn-primary, .btn-success, .btn-info, .btn-warning, .btn-danger, .btn-info:hover {color: $light;}

/* button size */
.btn.btn-very-small {font-size:9px; padding: 1px 17px; line-height: 22px;}
.btn.btn-small {font-size:11px; padding: 4px 24px;}
.btn.btn-medium {font-size:12px; padding: 6px 25px 5px;}
.btn.btn-large {font-size:13px; padding: 9px 34px; line-height: 25px}
.btn.btn-extra-large {font-size:15px; padding: 12px 40px 13px;  line-height: 25px}
.btn-dual .btn {margin-left: 10px; margin-right: 10px;}
.btn i {margin-left: 6px; vertical-align: middle; position: relative; top:-1px}

/* button background */
.btn.btn-white {background:$light; border-color: $light; color: $extra-dark-gray}
.btn.btn-white:hover, .btn.btn-white:focus {background: transparent; color: $light}
.btn.btn-black {background:$black; border-color: $black; color: $light}
.btn.btn-black:hover, .btn.btn-black:focus {background: transparent; color: $black}
.btn.btn-dark-gray {background: $extra-dark-gray; border-color: $extra-dark-gray; color: $light}
.btn.btn-dark-gray:hover, .btn.btn-dark-gray:focus {background: transparent; color: $extra-dark-gray}
.btn.btn-light-gray {background: $light-gray; border-color: $light-gray; color: $extra-dark-gray}
.btn.btn-light-gray:hover, .btn.btn-light-gray:focus {background: transparent; border-color: $light-gray; color: $light-gray}
.btn.btn-deep-pink {background: $primary; border-color: $primary; color: $light}
.btn.btn-deep-pink:hover, .btn.btn-deep-pink:focus {background: transparent; border-color: $primary; color: $primary}
.btn-warning, .btn-warning:hover {color: $light;}

/* button transparent */
.btn.btn-transparent-white {background: transparent; border-color: $light; color: $light}
.btn.btn-transparent-white:hover, .btn.btn-transparent-white:focus {background: $light; border-color: $light; color: $extra-dark-gray}
.btn.btn-transparent-black {background: transparent; border-color: $black; color: $black}
.btn.btn-transparent-black:hover, .btn.btn-transparent-black:focus {background: $black; border-color: $black; color: $light}
.btn.btn-transparent-dark-gray {background: transparent; border-color: $extra-dark-gray; color: $extra-dark-gray}
.btn.btn-transparent-dark-gray:hover, .btn.btn-transparent-dark-gray:focus {background: $extra-dark-gray; border-color: $extra-dark-gray; color: $light}
.btn.btn-transparent-light-gray {background: transparent; border-color: $light-gray; color: $light-gray}
.btn.btn-transparent-light-gray:hover, .btn.btn-transparent-light-gray:focus {background: $light-gray; border-color: $light-gray; color: $extra-dark-gray}
.btn.btn-transparent-deep-pink {background: transparent; border-color: $primary; color: $primary;}
.btn.btn-transparent-deep-pink:hover, .btn.btn-transparent-deep-pink:focus {background: $primary; border-color: $primary; color: $light; }

/* button rounded */
.btn.btn-rounded {border-radius: 50px}
.btn.btn-rounded.btn-very-small {padding: 2px 23px 1px;}
.btn.btn-rounded.btn-small {padding: 5px 29px;}
.btn.btn-rounded.btn-medium {padding: 6px 32px;}
.btn.btn-rounded.btn-large {padding: 9px 38px;}
.btn.btn-rounded.btn-extra-large {padding: 12px 45px 13px;}

/* image button */
.image-button {width: 100%; background: rgba(0,0,0,0.80); padding: 26px; font-family: $font-family-base, sans-serif; font-size: 16px;}
.image-button:hover {background: rgba(0,0,0,0.5);}

.tag-cloud a {font-size: 10px; padding: 3px 8px; border: 1px solid $border-color; margin: 0 8px 8px 0; display: inline-block; text-transform: uppercase; color: $extra-dark-gray; line-height: 18px;}
.tag-cloud a:hover{background: $extra-dark-gray; color: $light !important; border: 1px solid $extra-dark-gray;}

/* dropdown style 1 */
.dropdown-style-1 .btn {color: $light; font-size: 11px; line-height: normal; padding: 0; margin: 0; background: transparent; border: none; font-weight:normal}
.dropdown-style-1 .btn.dropdown-toggle::after {vertical-align: middle; border-top: .4em solid; border-right: .4em solid transparent; border-bottom: 0; border-left: .4em solid transparent; margin-left: 0;}
.dropdown-style-1 .btn:hover, .custom-dropdown btn:focus {color: var(--base-color);}
.dropdown-style-1 .dropdown-menu {margin-top: 2px; min-width: 130px; border-radius:0; border: none; z-index: 444; right: 0; left:auto !important; padding: 5px 0; transition-timing-function: ease-in-out; -ms-transition-timing-function: ease-in-out; -moz-transition-timing-function: ease-in-out; -webkit-transition-timing-function: ease-in-out; -o-transition-timing-function: ease-in-out; transition-duration: 0s; -ms-transition-duration: 0s; -moz-transition-duration: 0s; -webkit-transition-duration: 0s; -o-transition-duration: 0s;}
.dropdown-style-1 .dropdown-menu > li > a {padding: 6px 15px; font-size: 12px; display: block; line-height: normal;}
.dropdown-style-1 .dropdown-menu > li > a:hover,.dropdown-style-1 .dropdown-menu > li > a:focus {background: $very-light-gray}

/* button link */
.btn.btn-link {padding: 7px 0 0; position: relative; text-decoration: none; border: 0; border-bottom: 1px solid; background-color: transparent; letter-spacing: normal; line-height: 18px; transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out;}
